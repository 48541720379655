import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'


export class Annonce {
  uuid;// string;
  date;// Date;
  titre;// string;
  desc;// string;
  crit;// string;
  cat;// number;
  scat;// number;
  etat;// number;
  brand;// number;
  prix;// number;
  region;// number;
  dept;// number;
  gpslat;// number;
  gpslng;//number
  fact;// boolean;
  garanty;// boolean
  photo1;// boolean;
  photo2;// boolean;
  photo3;//boolean;
  photo4;//boolean;
  tel;// string;
  notel;// boolean;
  nocom;//boolean; // envoi possible
  userId;//string


    constructor() {
      this.uuid = uuidv4();
      this.date = firebase.firestore.FieldValue.serverTimestamp();
    }

    load(d /*: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>*/) {
      this.firestoreId = d.id;
      this.searchScore = 0;
      this.uuid = d.get("uuid");
      this.date = d.get("date").toDate();
      this.titre = d.get("titre");
      this.desc = d.get("desc");
      this.crit = d.get("crit");
      this.cat = d.get("cat");
      this.scat = d.get("scat");
      this.etat = d.get("etat");
      this.prix = d.get("prix");
      this.region = d.get("region");
      this.dept = d.get("dept");
      this.gpslat = d.get("gpslat");
      this.gpslng = d.get("gpslng");
      this.photo1 = d.get("photo1");
      this.photo2 = d.get("photo2");
      this.photo3 = d.get("photo3");
      this.photo4 = d.get("photo4");
      this.fact = d.get("fact");
      this.garanty = d.get("garanty");
      this.tel = d.get("tel");
      this.notel = d.get("notel");
      this.nocom = d.get("nocom");
      this.userId = d.get("userId");
      this.brand = d.get("brand");
    }
    
    
    containsString(search){
      var s = search.toLowerCase();
      var words = s.split(" ");
      this.searchScore = 0;

      if (this.titre.toLowerCase().indexOf(s)!=-1) {
        console.info("titre " + this.titre + " contains " + s);
        this.searchScore = 1000;
        return true;
      }
      if (this.desc.toLowerCase().indexOf(s)!=-1) {
        console.info("desc " + this.titre + " contains " + s);
        this.searchScore = 1000;
        return true;
      }

      for(let w in words){
        if (this.titre.toLowerCase().indexOf(words[w])!=-1) {
          console.info("titre " + this.titre + " contains " + words[w]);
          this.searchScore++;
          continue;
        }
        if (this.desc.toLowerCase().indexOf(words[w])!=-1) {
          console.info("desc " + this.titre + " contains " + words[w]);
          this.searchScore++;
          continue;
        }
      }
      

      return this.searchScore>0;
    }

    getAllImagesFileNames(){
      let result = [];
      if (this.photo1){
        result.push(this.getPhotoFileName(1));
      } else if (this.photo2){
        result.push(this.getPhotoFileName(2));
      } else if (this.photo3){
        result.push(this.getPhotoFileName(3));
      } else if (this.photo4){
        result.push(this.getPhotoFileName(4));
      }
      return result;
    }

    getImagesCount(){
      let result = 0;
      for(let i = 1 ; i <= 4;i++){
        if (this["photo"+i]) result++;
      }
      return result;
    }


    getPhotoFileName(photoNumber){
      return this.uuid + "_" + photoNumber + ".jpg";
    }

    setPhotoDeleted(photoNumber){
      switch(photoNumber){
        case 1: this.photo1 = false; break;
        case 2: this.photo2 = false; break;
        case 3: this.photo3 = false; break;
        case 4: this.photo4 = false; break;
      }
    }
  }
  export class AnnonceFromFireStore extends Annonce{
    firestoreId;//?string; // uniquement rempli lorsque l'annonce est chargée depuis firestore.
    searchScore;//?number;
  
    constructor() {
      super();
    }
  
    load(d){
      super.load(d);
      this.firestoreId = d.id;
      this.searchScore = 0;
    }
  }