import { db } from '../main'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

export default {

  saveEmail(email){
    
    return new Promise(
     (resolve, reject) => {
       db.collection('/newsletter/').doc(email).set({}).then(
         () => {
           resolve();
         }, (error) => {
           reject(error);
         }
       );
     }
   );
  },
  deleteEmail(email){
    return new Promise(
      (resolve, reject) => {
        console.log(email);
        db.collection('/newsletter/').doc(email).delete().then(
          () => {
            resolve();
          }, (error) => {
            reject(error);
          }
        );
      }
    );
  },


}