import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/app'
import 'firebase/auth'
import HomePage from '../app/page-component/home-page/home-page.vue'

Vue.use(VueRouter)

  const routes = [
    {
      path: '/',
      name: 'Home',
      component: HomePage
    },
    {
      path: '/recherche',
      name: 'Search',
      component: () => import('../app/page-component/search-page/search-page.vue')
    },
    {
      path: '/nouvelle-annonce',
      name: 'NewAd',
      component: () => import('../app/page-component/new-ad-page/new-ad-page.vue')
    },
    {
      path: '/favoris',
      name: 'Favorites',
      meta: {
        requiresAuth: true,
      },
      component: () => import('../app/page-component/my-account-favorites-page/my-account-favorites-page.vue')
    },
    {
      path: '/monCompte',
      name: 'MonCompte',
      meta: {
        requiresAuth: true,
      },
      component: () => import('../app/page-component/my-account-page/my-account-page.vue')
    },
    {
      path: '/monCompte/mesInformations',
      name: 'MyAccountInfo',
      meta: {
        requiresAuth: true,
      },
      component: () => import('../app/page-component/my-account-informations-page/my-account-informations-page.vue')
    },
    {
      path: '/monCompte/motdepasse',
      name: 'ModifyPassword',
      meta: {
        requiresAuth: true,
      },
      component: () => import('../app/page-component/my-account-password-page/my-account-password-page.vue')
    },
    {
      path: '/monCompte/mesAnnonces',
      name: 'MyAccountAds',
      meta: {
        requiresAuth: true,
      },
      component: () => import('../app/page-component/my-account-ads-page/my-account-ads-page.vue')
    },
    {
      path: '/annonce/:adId',
      name: 'Ad',
      props: true, 
      component: () => import('../app/page-component/ad-page/ad-page.vue')
    },
    {
      path: '/inscription',
      name: 'Register',
      component: () => import('../app/page-component/register-page/register-page.vue')
    },
    {
      path: '/connexion',
      name: 'Login',
      component: () => import('../app/page-component/login-page/login-page.vue')
    },
    {
      path: '/vendeur/:userId',
      name: 'Seller',
      props: true, 
      component: () => import('../app/page-component/seller-profile-page/seller-profile-page.vue')
    },
    {
      path: '/categorie/',
      props: true,
      name: 'Category',
      component: () => import('../app/page-component/category-page/category-page.vue')
    },
    {
      path: '/contactus',
      name: 'ContactUs',
      component: () => import('../app/page-component/contact-page/contact-page.vue')
    },
    {
      path: '/cgu',
      name: 'CGUPage',
      component: () => import('../app/page-component/cgu-page/cgu-page.vue')
    },
    {
      path: '/cookies',
      name: 'CookiesPage',
      component: () => import('../app/page-component/cookies-page/cookies-page.vue')
    },
    {
      path: '/mentionslegales',
      name: 'MentionsLegalesPage',
      component: () => import('../app/page-component/mentionslegales-page/mentionslegales-page.vue')
    },
    {
      path: '/blog',
      name: 'Blog',
      component: () => import('../app/page-component/blog/blog.vue')
    },
    // DEV: Error 500 component, giving fixed path while there is no backend
    {
      path: '/erreur500',
      name: 'ServerError',
      component: () => import('../app/shared-component/server-error-component/server-error-component.vue')
    },
    // Should always be last, catching every other path
    {
      path: '/*',
      name: 'PageNotFound',
      component: () => import('../app/shared-component/page-not-found-component/page-not-found-component.vue')
    },
  ]



const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})
router.beforeEach((to, from, next) =>{
    const currentUser = firebase.auth().currentUser;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    if (requiresAuth && !currentUser) {
      console.info("pas identifié, on renvoi vers l'ecran de connexion...");
      next('/connexion');
    }
    else {
      console.info("on renvoie vers la prochaine page : " + to.fullPath);
      console.trace();
      next();
    }
});

export default router
