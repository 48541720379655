import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
export class User {
    uuid;//: string;
    date;//: string;
    nom;//: string;
    phone;//: string;
    email;//: string;
    pro;//:boolean;
    adcount;// number;
    photo;//boolean;
    firestoreId;//?:string; // uniquement rempli lorsque l'utilisateur est chargée depuis firestore.

    constructor() {
        this.uuid = uuidv4();
        this.date = firebase.firestore.FieldValue.serverTimestamp();
        this.phone = "";
        this.photo = false;
        this.adcount = 0;
    }

    load(d){
      this.firestoreId = d.id;
      this.uuid = d.get("uuid");
      this.date = d.get("date").toDate();
      this.nom = d.get("nom");
      this.email = d.get("email");
      this.pro = d.get("pro");
      this.phone = d.get("phone");
      this.adcount = d.get("adcount");
      this.photo = d.get("photo");
      return this;
    }

    getPhotoFileName(){
      return this.uuid + ".jpg";
    }
  }

export class ProUser extends User{
  siret;//?:string;
  web;//?:string;

  constructor() {
    super();
  }

  load(d){
    super.load(d);
    this.siret = d.get("siret");
    this.web = d.get("web");
    return this;
  }
}

