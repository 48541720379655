<template>
  <div v-if="showPopup()"  id="pub">
    <OverlayComponent :mode="3" v-if="templateMode==1">
      <template v-slot:overlayContent>
        <div class="popupContainer" style="background-image: none">
          <div class="popupStyle">
            <div class="pubTextContent">
              <div style="margin-top: 0px">
                <button
                  @click="hidePub"
                  type="button"
                  aria-label="Close"
                  class="close text-dark closeButton"
                >
                  <svg
                    viewBox="0 0 16 16"
                    width="1.8em"
                    height="1.8em"
                    focusable="false"
                    role="img"
                    aria-label="x"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#9a9da0"
                    class="bi-x b-icon bi closeImage"
                  >
                    <g>
                      <path
                        fill-rule="evenodd"
                        d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"
                      ></path>
                    </g>
                  </svg>
                </button>
              </div>
              <p
                style="
                  text-align: center;
                  padding-left: 10px;
                  font-size: 45px;
                  color: #e7b9b7;
                "
              >
                <strong>Jeux concours</strong>
              </p>

              <p style="text-align: center">&nbsp;</p>
              <p style="text-align: center; margin-bottom: 50px">
                <img
                  width="300px"
                  src="../../../../assets/newBaniere.png"
                />
              </p>
              <p style="text-align: center; margin-top: 20px">
                <img
                  width="270px"
                  src="../../../../assets/icons/amazonCard.svg"
                />
              </p>
              <p
                style="
                  text-align: center;
                  width: 50%;
                  margin-left: auto;
                  margin-right: auto;
                  margin-bottom: 30px;
                  font-weight: bold;
                "
              >
                <strong>
                  en carte cadeau Amazon &agrave; gagner chaque semaine.<br
                /></strong>
              </p>
              <p
                style="
                  text-align: center;
                  font-weight: normal;
                  width: 80%;
                  margin-left: auto;
                  margin-right: auto;
                "
              >
                <strong
                  >Pour gagner il vous suffit simplement de vendre un maximum
                  d&rsquo;article&nbsp;</strong
                ><strong>dont vous ne vous servez plus !</strong>
              </p>
              <p style="text-align: center; margin-top: 20px">
                <strong>Bonne chance !</strong>
              </p>
              <p
                style="
                  text-align: center;
                  width: 50%;
                  margin-left: auto;
                  margin-right: auto;
                  margin-top: 20px;
                "
              >
                <!-- <button
                  style="
                    padding: 10px 30px 10px 30px;
                    border-radius: 5em;
                    background-color: #e7b9b7;
                    border-width: 0;
                    font-weight: bold;
                    color: white;
                  "
                >
                  <p>Je participe</p>
                </button> -->
                <b-button to="/nouvelle-annonce" type="submit" variant="primary" class="participe_button">Je participe !</b-button>
              </p>
            </div>
          </div>
        </div>
      </template>
    </OverlayComponent>
    <OverlayComponent :mode="3" v-if="templateMode==3">
      <template v-slot:overlayContent>
        <div class="popupContainer" style="background-image: none">
          <div class="popupStyle">
            <div class="pubTextContent">
              <div style="margin-top: 0px">
                <button
                  @click="hidePub"
                  type="button"
                  aria-label="Close"
                  class="close text-dark closeButton"
                >
                  <svg
                    viewBox="0 0 16 16"
                    width="1.8em"
                    height="1.8em"
                    focusable="false"
                    role="img"
                    aria-label="x"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#9a9da0"
                    class="bi-x b-icon bi closeImage"
                  >
                    <g>
                      <path
                        fill-rule="evenodd"
                        d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"
                      ></path>
                    </g>
                  </svg>
                </button>
              </div>
              <p
                style="
                  text-align: center;
                  padding-left: 10px;
                  font-size: 30px;
                  color: #e7b9b7;
                "
              >
                <strong>Jeu concours</strong>
              </p>

              <p style="text-align: center">&nbsp;</p>
              <p style="text-align: center; margin-bottom: 20px">
                <img
                  width="210px"
                  src="../../../../assets/icons/logoshowroombaby.svg"
                />
              </p>
              <p style="text-align: center; margin-top: 20px">
                <img
                  width="270px"
                  src="../../../../assets/icons/amazonCard.svg"
                />
              </p>
              <p
                style="
                  text-align: center;
                  width: 90%;
                  margin-left: auto;
                  margin-right: auto;
                  margin-bottom: 5px;
                  font-weight: bold;
                "
              >
                <strong>
                  en carte cadeau Amazon &agrave; gagner chaque semaine.<br
                /></strong>
              </p>
              <p
                style="
                  text-align: center;
                  font-weight: normal;
                  width: 100%;
                  margin-left: auto;
                  margin-right: auto;
                  font-size:14px
                "
              >
                <strong
                  >Pour gagner il vous suffit simplement de vendre un maximum
                  d&rsquo;article&nbsp;</strong
                ><strong>dont vous ne vous servez plus !</strong>
              </p>
              <p style="text-align: center; margin-top: 20px">
                <strong>Bonne chance !</strong>
              </p>
              <p
                style="
                  text-align: center;
                  width: 50%;
                  margin-left: auto;
                  margin-right: auto;
                  margin-top: 20px;
                "
              >
                <!-- <button
                  style="
                    padding: 10px 30px 10px 30px;
                    border-radius: 5em;
                    background-color: #e7b9b7;
                    border-width: 0;
                    font-weight: bold;
                    color: white;
                  "
                >
                  <p>Je participe</p>
                </button> -->
                <b-button to="/nouvelle-annonce" type="submit" variant="primary" class="participe_button_mobil">Je participe !</b-button>
              </p>
              
            </div>
          </div>
        </div>
      </template>
    </OverlayComponent>
  </div>
  
</template>

<script>
import OverlayComponent from "../../../shared-component/overlayComponent/overlayComponent.vue";

export default {
  name: "Pub",
  props: ["templateMode", "route"],
  components: {
    OverlayComponent,
  },
  data: () => {
    return {
      closed: false,
    };
  },

  methods: {
    hidePub() {
      console.log("hidePub");
      this.closed = true;
      this.$cookies.set("pub_hidden", "true", 24 * 60 * 60); // cachée pour 24 heures
    },
    showPopup() {
      if (this.closed) return false;

      return this.$cookies.get("pub_hidden") != "true" && this.route == "/";
    },
  },
};
</script>

<style lang="scss">
@import "pub.scss";
</style>