import { db } from '../main'

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'


export class Favorite{
  annonceId;

  constructor(a){
    this.annonceId = a;
  }
}

export default {

  addToFavoriteWithFireStoreId(firestoreId){
    let fav = new Favorite(firestoreId);

    if (firebase.auth().currentUser !=null){
      let userId = firebase.auth().currentUser.uid;
      return new Promise(
      (resolve, reject) => {
        db.collection('favorites').doc(userId).collection('list').doc(firestoreId).set({...fav}).then(
              () => {
                resolve();
              }, (error) => {
                reject(error);
              }
            );
          }
        );
    } else {
      console.info("utilisateur non authentifié, on ne peut pas ajouter un favoris !");
      return new Promise();
    }
  },

  addToFavorite(annonce){
    return this.addToFavoriteWithFireStoreId(annonce.firestoreId);
  },

  isFavoriteWithFireStoreId(firestoreId){
    if (firebase.auth().currentUser !=null){
      let userId = firebase.auth().currentUser.uid;
      return new Promise(
        (resolve) => {
          db.collection('favorites').doc(userId).collection('list').doc(firestoreId).get().then(
            (snapshot) => {
              let d = snapshot.data();
              if (d != undefined){
                resolve(snapshot.data().annonceId == firestoreId);
              } else {
                resolve(false);
              }
              
            }, () => {
              resolve(false);
            }
          );
        }
      );
    } else {
      return new Promise((reject) => {
         reject();
      });
    }
  },

  isFavorite(annonce) {
    return this.isFavoriteWithFireStoreId(annonce.firestoreId);
  },

  getFavorites()  {
    if (firebase.auth().currentUser !=null){
      let userId = firebase.auth().currentUser.uid;
      return new Promise(
        (resolve) => {
          db.collection('favorites').doc(userId).collection('list').get().then(
            (snapshot) => {
              let liste = [];
              for (let d of snapshot.docs) {
                liste.push(d.id);
              }
              resolve(liste);
            }, () => {
              resolve([]);
            }
          );
        }
      );
    } else {
      return new Promise((resolve) => {
        resolve([]);
      });
    }
  },

  deleteFavoriteWithFireStoreId(firestoreId) {

    if (firebase.auth().currentUser !=null){
      let userId = firebase.auth().currentUser.uid;
      return new Promise(
      (resolve, reject) => {
        db.collection('favorites').doc(userId).collection('list').doc(firestoreId).delete().then(
              () => {
                resolve();
              }, (error) => {
                reject(error);
              }
            );
          }
        );
    } else {
      console.info("utilisateur non authentifié, on ne peut pas supprimer un favoris !");
      return new Promise();
    }
  },

  deleteFavorite(annonce) {
    return this.deleteFavoriteWithFireStoreId(annonce.firestoreId);
  }

  


}