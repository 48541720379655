import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
export class Message {
    uuid;
    date;
    authorId;
    authorEmail;
    message;
    userDestId;
    userDestEmail;
    userDestName;
    annonceTitle;
    annonceId;
   

    constructor(authorId,authorEmail,message,userDestId,userDestEmail,userDestName,annonceTitle,annonceId) {
        this.uuid = uuidv4();
        this.date = firebase.firestore.FieldValue.serverTimestamp();
        this.authorId = authorId;
        this.authorEmail = authorEmail;
        this.message = message;
        this.userDestId = userDestId;
        this.userDestEmail = userDestEmail;
        this.userDestName = userDestName;
        this.annonceTitle = annonceTitle;
        this.annonceId = annonceId;
        if (this.authorId == undefined){
            this.authorId = "unloggedUser";
        }
    }

   
}


