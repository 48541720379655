<script>
export default {
    name: "OverlayComponent",
    components: {
    },
    props:['titre','closeCallback','mode'],
    data() {
        return {
            show:false,
        }
    },
    created(){
      
    },
    methods: {
      getOverlayMainDivStyle(){
          if (this.mode==3){
              return "max-width:none;"
          }
          return "";
      },
      setVisible(b){
          this.show = b;
      },
      toggle(){
          this.show = !this.show;
      },
      getTopDivStyle(){
          if (this.mode && this.mode != 3){
              return ""
          }
          if (this.mode==3){
              return "max-width:none;"
          }
          return "";
      },
      getContentDivStyle(){
          if (this.mode == 1 || this.mode == 2 || this.mode == 3){

              let height = "450px";
              let width = "700px";
              if (this.mode == 2){
                  height = "240px";
                  width = "380px"
              }else if (this.mode == 3){
                  height = "240px";
                  width = "380px"
                  return "background-color:transparent;\
                          margin: 0 auto;\
                          position: absolute;\
                           left:50vw;\
                      top: -92vh;\
                      bottom : auto;\
                       translateX(-50%);\
                      -moz-transform: translateX(-50%);\
                      -webkit-transform: translateX(-50%);\
                      -ms-transform: translateX(-50%);\
                      -o-transform: translateX(-50%);";
                     
              }

              return "box-shadow: 3px 3px 5px 3px #ebebeb;\
                      border-bottom-left-radius: 28px;\
                      border-bottom-right-radius: 28px; \
                      margin: 0 auto;\
                      position: absolute;\
                      left:50vw;\
                      top: -75vh;\
                      width:"+width+";\
                      height:"+height+";\
                      translateX(-50%);\
                      -moz-transform: translateX(-50%);\
                      -webkit-transform: translateX(-50%);\
                      -ms-transform: translateX(-50%);\
                      -o-transform: translateX(-50%);";
          }
          return "";
      }

    }    
};
</script>

<style lang="scss">
    @import "overlayComponent.scss";
</style>

<template src="./overlayComponent.html"></template>