<template>
    <div class="modal-info-component">
        <b-modal v-model="showed" ok-only
            id="Alert-modal" 
            :centered="true" 
            footer-class="centered-footer"
            :hide-header="true"
            :ok-title="fermerTitle?fermerTitle:'Fermer'"
            title="AlertModal"
            @ok="userValidation"
            hide-footer>
            <p class="">{{ modalMessage }}</p>
            <b-button style="background-color:#1559b5;" class="mt-3" block @click="userValidation()">{{fermerTitle?fermerTitle:'Fermer'}}</b-button>
            <div style="height:10px">  </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "ModalInfoComponent",
    components: {
    },
    props: ['modalMessage','okFunc','fermerTitle'],
    data() {
        return {
            showed:false,
        }
    },
    methods: {
        showModal() {
            this.showed = true;
        },
        userValidation() {
            console.log('confirmed modal action')
            this.okFunc();
             this.showed = false;
        },
    }
};
</script>

<style lang="scss">
    @import "modal-info-component.scss";
</style>