import UserServices from './UsersService.js'
import {User} from '../model/User.js'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

export default {
  isLogged(){
    return firebase.auth().currentUser !=null;
  },

  getProviderId(){
    let fireBaseUser = firebase.auth().currentUser;
    return fireBaseUser.providerData[0].providerId;
  },
  isGoogleAccount(){
    let fireBaseUser = firebase.auth().currentUser;
    return fireBaseUser.providerData[0].providerId == 'google.com';
  },

  isFacebookAccount(){
    let fireBaseUser = firebase.auth().currentUser;
    return fireBaseUser.providerData[0].providerId == 'facebook.com';
  },

  getCurrentUserId(){
    return firebase.auth().currentUser.uid;
  },

  getCurrentFirebaseUser(){
    return firebase.auth().currentUser;
  },

  deleteUser(){
    return firebase.auth().currentUser.delete();
  },

  createNewUser(email, password) {
    return new Promise(
      (resolve, reject) => {
        firebase.auth().createUserWithEmailAndPassword(email, password).then(
          () => {
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  },

  signInUser(email, password) {
    return new Promise(
      (resolve, reject) => {
        firebase.auth().signInWithEmailAndPassword(email, password).then(
          () => {
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  },


  signInGoogleUser() {
    var provider = new firebase.auth.GoogleAuthProvider();

    return new Promise(
      (resolve, reject) => {
        firebase.auth().signInWithPopup(provider).then(
          (result) => {
              // This gives you a Google Access Token. You can use it to access the Google API.
              var token = result.credential.accessToken;
              // The signed-in user info.
              var user = result.user;
              console.info("google login success : token = " + token + " , user.email = " + user.email + " , " + user.displayName);
              UserServices.doesUserExists(firebase.auth().currentUser.uid).then((exists)=>{
                if (exists){
                  resolve();
                } else {
                  let u = new User();
                  u.email = user.email;
                  u.nom = user.displayName;
                  u.pro = false;
                  UserServices.setUser(u).then(()=>{
                    resolve();
                  });
                }

              },(error)=>{
                reject(error);
              });

         
            },
          (error) => {
             // Handle Errors here.
             var errorCode = error.code;
             var errorMessage = error.message;
             // The email of the user's account used.
             var email = error.email;
             // The firebase.auth.AuthCredential type that was used.
             var credential = error.credential;
             console.error("google login error : errorCode = " + errorCode + " , errorMessage = " + errorMessage + " , email = " + email + " , credential = " + credential);
             reject(error);
          }
        );
      }
    );
  },

  signInFacebookUser() {
    var provider = new firebase.auth.FacebookAuthProvider();

    return new Promise(
      (resolve, reject) => {
        firebase.auth().signInWithPopup(provider).then(
          (result) => {
              // This gives you a Google Access Token. You can use it to access the Google API.
              var token = result.credential.accessToken;
              // The signed-in user info.
              var user = result.user;
              console.info("facebook login success : token = " + token + " , user.email = " + user.email + " , " + user.displayName);
              UserServices.doesUserExists(firebase.auth().currentUser.uid).then((exists)=>{
                if (exists){
                  resolve();
                } else {
                  let u = new User();
                  u.email = user.email;
                  u.nom = user.displayName;
                  u.pro = false;
                  UserServices.setUser(u).then(()=>{
                    resolve();
                  });
                }

              },(error)=>{
                reject(error);
              });

         
            },
          (error) => {
             // Handle Errors here.
             var errorCode = error.code;
             var errorMessage = error.message;
             // The email of the user's account used.
             var email = error.email;
             // The firebase.auth.AuthCredential type that was used.
             var credential = error.credential;
             console.error("facebook login error : errorCode = " + errorCode + " , errorMessage = " + errorMessage + " , email = " + email + " , credential = " + credential);
             reject(error);
          }
        );
      }
    );
  },


  signOutUser() {
    return firebase.auth().signOut();
  },

  changePassword(oldPassword,newPassword){
    let user = firebase.auth().currentUser;
    let email = user.email;
    console.info("email : " + email);
    return new Promise(
      (resolve, reject) => {
        firebase.auth()
        .signInWithEmailAndPassword(email, oldPassword)
        .then(function() {
            firebase.auth().currentUser.updatePassword(newPassword).then(function(){
                //Do something
                resolve();
            }).catch(function(err){
              reject("Modification du mot de passe échouée ! Detail : " + err);
            });

        }).catch(function(){
            //Do something
            reject("Vérification du mot de passe actuel échouée !" );
        });
      });
  },

  sendResetPasswordEmail(email){
    return firebase.auth().sendPasswordResetEmail(email);
  }
  

  
}