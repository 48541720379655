// import favoriteIconImported from '../assets/icons/addition.svg'
// import unFavoriteIconImported from '../assets/icons/deletion.svg'
// import errorImported from '../assets/icons/error.svg'

// ** Background image passed in popToast function are defined in /styles/_utilities.scss
// Defined as a background images classes, then assigned but popToast function
// PopToast take a message as an argument (defined here) and a string that link the toast with a classes (defined in _utilities)

export const ToastMixin = {
    data() {
        return {
            // Added to favorite
            adFavoriteMessage: "L'annonce a bien été ajoutée aux favoris",
            // Removed from favorite
            adUnfavoriteMessage: "L'annonce a bien été retirée des favoris",
            // Error message (Basic & input file error)
            errorMessage: 'oups, une erreur est survenue',
            errorMessageInputFile: '4 images maximum par annonce',
        }
    },
    methods : {
        popToast(message, icon) {
            if (message == this.adFavoriteMessage || message == this.adUnfavoriteMessage){
                return ;
            }
            // Use a shorter name for this.$createElement
            const h = this.$createElement
            // Create the message
            const vNodesMsg = h(
                'p',
                { class: ['mb-0', 'greytxt'] },
                [
                    h('b-img', {class: ['ml-3', 'mr-3']}),
                    message,
                ],
            )
            // Pass the VNodes as an array for message and title
            this.$bvToast.toast([vNodesMsg], {
                solid: true,
                variant: 'default',
                autoHideDelay: 1500,
                bodyClass: `toast-body-img ${icon}`
            })
        }
    }
}