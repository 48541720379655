<template >
 <div :style="getCardSizeStyle()" class="vertical-annonce-container" >
        <router-link :style="annonce?'':'cursor:auto;'" :to="annonce?{ name: 'Ad', params: { adId: annonce.firestoreId }}:''">
            <b-card :img-src="ad_image" img-alt="Card image" img-top class="mt-2" border-variant="light">
                <b-card-text>
                    <div class="price-like-container">
                            <div class="pink-price" >{{ niceText(annonce?annonce.prix:0,9) }}€</div>
                            
                            <!-- <template v-if="favorite != undefined">
                                <img @click="favouriteSwitch($event, favorite)" v-if="!favorite" src="../../../assets/icons/0141-heart.svg" alt="coeur remplis">
                                <img @click="favouriteSwitch($event, favorite)" v-else src="../../../assets/icons/0141-heart-full.svg" alt="coeur vide">
                            </template>
                            -->
                    </div>
                     <h6 class="verticalTitre" style="font-weight:bold;color:#143A6D">{{ annonce?niceText(annonce.titre,20):'titre' }}</h6>
                     <div class="verticalLocation">{{ annonce?niceText(getDepartementByCode(annonce.dept).text,25):'Dept' }}
                    </div>
                        
                </b-card-text>
            </b-card>
        </router-link>
    </div>
   
</template>

<script>

import StorageService from '../../../services/StorageService.js'
import FavoritesService from '../../../services/FavoritesService.js'
import AuthService from '../../../services/AuthService.js'
import Const from "../../../model/Const.js"

export default {
    name: "VerticalAnnonce",
    props: ['dept','region', 'price','annonce','cardWidth','cardHeight'],

    data() {
        return { 
            favorite:undefined,
            default_image :require(`../../../assets/new_logo.jpeg`),
            ad_image:undefined,
        }
    },
    watch: {
         annonce: {
            handler(newVal, oldVal) {
                console.info("annonce : " + newVal + " , " + oldVal);
            this.updateYou();
            }
        },    
    },
    methods: {
        getCardSizeStyle(){
            let result = this.annonce?'':'opacity:0;';
            result = result + "--cardWidth: " + this.cardWidth + "px;--cardHeight: " + (this.cardHeight)  + "px;";
            return result;
        },
        // Switch for favorite / unfavorite ad
        favouriteSwitch(event, favorite) {
            if (!favorite){
                FavoritesService.addToFavorite(this.annonce).then(()=> this.updateFavoriteState());
            } else {
                FavoritesService.deleteFavorite(this.annonce).then(()=> this.updateFavoriteState());
            }

            favorite = !favorite
            console.log('fav in ad', favorite)
            console.log('ad index in ad', this.annonce.firestoreId)
       

            // Cancel the clickable link for ad
            event.preventDefault()
            this.$emit('favoriteState', favorite, this.annonce.firestoreId)
        },
        getDepartementByCode(code){
            return Const.getDepartementByCode(code);
        },
        getRegionByCode(code){
            return Const.getRegionByCode(code);
        },
        updateFavoriteState(){
            FavoritesService.isFavorite(this.annonce).then((b)=>{
                this.favorite = b;
            },() =>{
                this.favorite = undefined;
            });
         },
         niceText(text,limit){
             if (text=='') return 'Pas de titre';
             let txt = ""+text;
             if (txt.length > limit){
                  return txt.substr(0,Math.max(1,limit-3)) + "...";
             }
             return txt;
         },

         updateYou(){
            if (this.annonce){
                if (this.annonce.photo1){
                StorageService.getDownloadURL(this.annonce,1).then((url)=>{
                    this.ad_image = url;
                    // console.info("ad_image = " + this.ad_image);
                });
                }
                if (!AuthService.isLogged() || AuthService.getCurrentUserId() == this.annonce.userId){
                    this.favorite = undefined;
                } 
            } else {
                this.ad_image = this.default_image;
                this.favorite = undefined;
            }
         }
    },
   
    created(){
        this.ad_image = this.default_image;
    },
    mounted(){
        console.info("mounted");
        this.updateYou();
    }

    
};
</script>

<style lang="scss">
    @import "vertical-annonce.scss";
</style>