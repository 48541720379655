<template>
  <div id="BurgerMenuContainer">
    <img
      v-if="headerState != 6"
      @click="burgerClick()"
      :class="templateMode == 3 ? 'burgerButton' : 'burgerButtonBig'"
      src="@/assets/icons/burgerButtonBold.svg"
    />
    <b-sidebar
      no-header
      width="80%"
      id="burgerSideBar"
      title="Carte"
      left
      shadow
      lazy
      backdrop-variant="dark"
      backdrop
    >
      <template #default="{ hide }">
        <div class="burgerSideBarMenu">
          <header class="b-sidebar-header">
            <button
              @click="hide"
              type="button"
              aria-label="Close"
              :class="
                templateMode == 3
                  ? 'close text-dark closeButton'
                  : 'close text-dark closeButtonBig'
              "
            >
              <svg
                viewBox="0 0 16 16"
                focusable="false"
                role="img"
                aria-label="x"
                xmlns="http://www.w3.org/2000/svg"
                fill="#FFFFFF"
                class="bi-x b-icon bi closeImage"
              >
                <g>
                  <path
                    fill-rule="evenodd"
                    d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"
                  ></path>
                </g>
              </svg>
            </button>
          </header>
        </div>
        <div class="menuContainer">
          <nav class="burgerLinks">
            <b-nav vertical>
              <b-nav-item to="/" @click="hide">
                <img
                  style="
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                    width: 240px;
                  "
                  class="showroombabytitle"
                  src="@/assets/newBaniere.png"
                />
              </b-nav-item>
              <b-nav-item class="menuLine" to="/recherche" @click="hide">
                <img
                  src="@/assets/pages-assets/commons/navbar/magnifier.svg"
                  alt="lien de la barre de navigation vers rechercher"
                />
                <span>RECHERCHER</span>
              </b-nav-item>
              <b-nav-item class="menuLine publier" to="/nouvelle-annonce" @click="hide">
                <span>PUBLIER UNE ANNONCE</span>
              </b-nav-item>
              <hr
                style="
                  width: 100%;
                  text-align: left;
                  margin-left: 0;
                  border-top: 1px solid #cad1da;
                "
              />

              <!--<b-nav-item v-if="isLogged()" class="menuLine" @click="hide"> -->
              <!--<img src="@/assets/pages-assets/commons/navbar/user.svg" alt="lien de la barre de navigation vers votre profile">-->
              <span
                v-if="isLogged()"
                class="menuLine"
                style="margin-bottom: 10px"
                >MON COMPTE</span
              >
              <!--</b-nav-item>-->
              <b-nav-item
                v-if="!isLogged()"
                class="menuLine"
                to="/monCompte"
                @click="hide"
              >
                <img
                  src="@/assets/pages-assets/commons/navbar/user.svg"
                  alt="lien de la barre de navigation vers votre profile"
                />
                <span>CONNEXION / INSCRIPTION</span>
              </b-nav-item>
              <b-nav-item
                v-if="isLogged()"
                class="menuLine moncompteitem"
                to="/favoris"
                @click="hide"
              >
                <img
                  src="@/assets/icons/favoris_noir.svg"
                  alt="lien de la barre de navigation vers favoris"
                />
                <span>FAVORIS</span>
              </b-nav-item>
              <b-nav-item
                v-if="isLogged()"
                class="menuLine moncompteitem"
                to="/monCompte/mesAnnonces"
                @click="hide"
              >
                <img
                  src="@/assets/icons/mesannonces.svg"
                  alt="lien de la barre de navigation vers favoris"
                />
                <span>MES ANNONCES</span>
              </b-nav-item>
              <b-nav-item
                v-if="isLogged()"
                class="menuLine moncompteitem"
                to="/monCompte/mesInformations"
                @click="hide"
              >
                <img
                  src="@/assets/icons/monprofil.png"
                  alt="lien de la barre de navigation vers favoris"
                />
                <span>MON PROFIL</span>
              </b-nav-item>
              <b-nav-item
                v-if="isLogged()"
                class="menuLine moncompteitem"
                to="/monCompte/motdepasse"
                @click="hide"
              >
                <img
                  src="@/assets/icons/motdepasse.png"
                  alt="lien de la barre de navigation vers favoris"
                />
                <span>MOT DE PASSE</span>
              </b-nav-item>
              <b-nav-item
                v-if="isLogged()"
                class="menuLine moncompteitem"
                to="/monCompte"
                @click="
                  hide,
                  deconnexion();
                "
              >
                <img
                  src="@/assets/icons/sedeconnecter.png"
                  alt="lien de la barre de navigation vers favoris"
                />
                <span>SE DECONNECTER</span>
              </b-nav-item>
            </b-nav>
            <b-nav vertical class="stuckAtBottom">
              <hr
                style="
                  width: 100%;
                  text-align: left;
                  margin-left: 0;
                  border-top: 1px solid #cad1da;
                "
              />
              <b-nav-item
                :class="templateMode==3?'menuLine moncompteitem_mobil':'menuLine moncompteitem'"
                to="/contactus"
                @click="hide"
                
              >
                <span>Nous contacter</span>
              </b-nav-item>
              <b-nav-item
                :class="templateMode==3?'menuLine moncompteitem_mobil':'menuLine moncompteitem'"
                to="/cgu"
                @click="hide"
              >
                <span >Conditions Générales d'utilisation</span>
              </b-nav-item>
              <b-nav-item
                :class="templateMode==3?'menuLine moncompteitem_mobil':'menuLine moncompteitem'"
                to="/cookies"
                @click="hide"
              >
                <span>Cookies</span>
              </b-nav-item>
              <b-nav-item
                :class="templateMode==3?'menuLine moncompteitem_mobil':'menuLine moncompteitem'"
                to="/mentionslegales"
                @click="hide"
              >
                <span>Mentions légales</span>
              </b-nav-item>
            </b-nav>
          </nav>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import AuthService from "../../../../services/AuthService.js";
import router from "../../../../router";
export default {
  name: "BurgerMenu",
  props: ["route", "templateMode", "headerState"],
  methods: {
    burgerClick() {
      this.$root.$emit("bv::toggle::collapse", "burgerSideBar");
    },
    isLogged() {
      return AuthService.isLogged();
    },
    deconnexion() {
      AuthService.signOutUser().then(
        () => {
          console.info("deconnexion reussie !");
          router.push("/");
        },
        (error) => {
          this.errorMessage = error;
          console.error("deconnexion echouee : " + error);
        }
      );
    },
  },
};
</script>

<style lang="css">
@import "./burgerMenu.css";
</style>