export const FormValidatorsMixin = {
    // Your form & formValid MUST have the same key passed in props name
    methods : {
        // Method used when child component emit value to parent
        setValidatedState(validated, inputValue, fieldName) {
            // Data from InputValidatorsComponent (field is valid, input value, fieldName)
            console.log('in parent condition true/false:', validated)
            console.log('in parent valeurs:', inputValue)
            console.log('in parent validator type:', fieldName)

            console.log('avant:', this.form)
            console.log('avant:',this.formValid)
            if(validated === true) {
                for (const key in this.form) {
                    if(key === fieldName) {
                        this.form[key] = inputValue
                        this.formValid[key] = true
                    }
                }
            }
            else {
                for (const key in this.form) {  
                    if(key === fieldName) {
                        this.form[key] = ''
                        this.formValid[key] = false
                    }
                }
            }
            console.log('après:',this.form)
            console.log('après:',this.formValid)
            if (this.afterSetValidatedState){
                this.afterSetValidatedState(validated, inputValue, fieldName);
            } else {
                //console.info("afterSetValidatedState does not exists");
            }
        },
        // Method used for submitting form
        onFormSubmit(event) {
            console.log('FormJSON :', JSON.stringify(this.form))

            if(this.checkForm(this.formValid)) {
                // BACKEND here AJAX call
                console.log('Form valide!', this.formValid) 
            }
            else console.log('Form pas valide !', this.formValid)
            event.preventDefault()
            // TODO Then redirect to fitting category with possible location/keyword param
        },
        // Method using simple loop the check if every formValid value is egal true
        // If true send form, if not true block form
        checkForm(formValid) {
            for (const property in formValid) {
                if(formValid[property] != true) {
                    return false
                }
            }
            return true
        },
    }
}