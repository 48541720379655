<template>
  <header id="Header-component-container">
    <nav id="Fixed-header" ref="fixedHeader" :class="{visible:isTopBarVisible}">
      <div v-if="templateMode > 1" class="headerContainerPart">
        <div class="headerMainPart">
          

          <router-link
            to="/"
            v-show="
              headerState <= 2 ||
              headerState == 5 ||
              headerState == 6 ||
              (headerState == 4 && templateMode < 3)
            "
          >
            <div id="over" style="width: 100%">
              <img
                width="240px"
                style="margin-left: auto; margin-right: auto; display: block"
                src="@/assets/newBaniere.png"
                :class="
                  templateMode == 3
                    ? 'showroombabytitleMobile'
                    : 'showroombabytitle'
                "
              />
            </div>
          </router-link>
          <router-link to="/" v-show="headerState == 3">
            <h1 :class="templateMode == 3 ? 'otherTitleMobile' : 'otherTitle'">
              <img src="@/assets/newBaniere.png" alt="logoShowroom" />
            </h1>
          </router-link>
          <router-link to="/" v-show="headerState == 4 && templateMode == 3">
            <h1 :class="templateMode == 3 ? 'otherTitleMobile' : 'otherTitle'">
              Publier
            </h1>
          </router-link>
          <router-link to="/" v-show="headerState == 7">
            <h1 :class="templateMode == 3 ? 'otherTitleMobile' : 'otherTitle'">
              <img src="@/assets/newBaniere.png" alt="logoShowroom" />
            </h1>
          </router-link>
          <router-link to="/" v-show="headerState == 8">
            <h1 :class="templateMode == 3 ? 'otherTitleMobile' : 'otherTitle'">
              Mon profil
            </h1>
          </router-link>
          <router-link to="/" v-show="headerState == 9">
            <h1 :class="templateMode == 3 ? 'otherTitleMobile' : 'otherTitle'">
              Mot de passe
            </h1>
          </router-link>
          <router-link to="/" v-show="headerState == 10">
            <h1 :class="templateMode == 3 ? 'otherTitleMobile' : 'otherTitle'">
              <img src="@/assets/icons/logoshowroombaby.svg" alt="Logo showRoom"/>
            </h1>
          </router-link>
          <router-link to="/" v-show="headerState == 11">
            <h1 :class="templateMode == 3 ? 'otherTitleMobile' : 'otherTitle'">
              C.G.U.
            </h1>
          </router-link>
          <nav v-if="headerState != 4 && templateMode == 1" class="navlinks">
            <router-link to="/recherche" class="bottombarButtons pinkhover">
              <div>
                <img
                  v-if="this.route == '/recherche'"
                  src="@/assets/pages-assets/commons/navbar/magnifier_selected.svg"
                  alt="lien de la barre de navigation vers recherche"
                />
                <img
                  v-else
                  src="@/assets/pages-assets/commons/navbar/magnifier.svg"
                  alt="lien de la barre de navigation vers recherche"
                />
              </div>
            </router-link>
            <router-link
              to="/nouvelle-annonce"
              class="bottombarButtons pinkhover"
            >
              <div>
                <img
                  v-if="this.route == '/nouvelle-annonce'"
                  src="@/assets/pages-assets/commons/navbar/plus-square_selected.svg"
                  alt="lien de la barre de navigation vers nouvelle annonce"
                />
                <img
                  v-else
                  src="@/assets/pages-assets/commons/navbar/plus-square.svg"
                  alt="lien de la barre de navigation vers nouvelle annonce"
                />
              </div>
            </router-link>
            <router-link to="/favoris" class="bottombarButtons pinkhover">
              <div>
                <img
                  v-if="this.route == '/favoris'"
                  src="@/assets/pages-assets/commons/navbar/heart_selected.svg"
                  alt="lien de la barre de navigation vers favoris"
                />
                <img
                  v-else
                  src="@/assets/pages-assets/commons/navbar/heart.svg"
                  alt="lien de la barre de navigation vers favoris"
                />
              </div>
            </router-link>
            <router-link to="/monCompte" class="bottombarButtons pinkhover">
              <div>
                <img
                  v-if="this.route == '/monCompte'"
                  src="@/assets/pages-assets/commons/navbar/user_selected.svg"
                  alt="lien de la barre de navigation vers votre profile"
                />
                <img
                  v-else
                  src="@/assets/pages-assets/commons/navbar/user.svg"
                  alt="lien de la barre de navigation vers votre profile"
                />
              </div>
            </router-link>
          </nav>

          <nav
            v-if="
              headerState != 4 &&
              headerState != 5 &&
              headerState != 6 &&
              templateMode > 1
            "
            class="navlinks"
          >
            <router-link to="/recherche" class="bottombarSearchAlone">
              <div>
                <img
                  style="margin-top: 5px"
                  src="@/assets/pages-assets/commons/navbar/magnifier_black.svg"
                  alt="lien de la barre de navigation vers recherche"
                />
              </div>
            </router-link>
          </nav>

          <nav v-if="headerState == 5 && templateMode > 1">
            <div class="closeSearchContainer" @click="closeSearch()">
              <svg
                viewBox="0 0 16 16"
                width="2em"
                height="2em"
                focusable="false"
                role="img"
                aria-label="x"
                xmlns="http://www.w3.org/2000/svg"
                fill="#9a9da0"
              >
                <g>
                  <path
                    fill-rule="evenodd"
                    d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"
                  ></path>
                </g>
              </svg>
            </div>
          </nav>

          <b-form v-show="headerState == -1" @submit.prevent="launchSearch()">
            <InputValidatorsComponent
              left_icon_input="true"
              class="home-search"
              placeholder="Que recherchez-vous ?"
              type="text"
              name="keywords"
              rule="min:1|max:200"
              @validationState="setValidatedState"
              ref="searchInput"
            />
          </b-form>
          <div v-show="headerState != -1" class="emptyBottomSpace"></div>
        </div>
        <div class="horizontalLineSeparator"></div>
      </div>
      <div v-if="templateMode == 1" class="headerContainerPart">
        <div class="headerMainPart">
          <div class="emptyBottomSpace"></div>
          <table>
            <tbody>
              <tr>
                <td>
                  <router-link to="/">
                    <div id="over" style="width: 100%">
                      <img
                        width="230px"
                        style="margin-left: 0px;margin-right:20px; display: block"
                        src="@/assets/newBaniere.png"
                        :class="
                          templateMode == 3
                            ? 'showroombabytitleMobile'
                            : 'showroombabytitle'
                        "
                      />
                    </div>
                  </router-link>
                </td>
                <td class="rechercher-un-article" :style="isInLogPage()? 'display:none': 'display:block;'">
                  <router-link
                    to="/recherche"
                    class="bottombarButtons pinkhover"
                  >
                    <div id="groupe">
                    
                      
                      <p>Rechercher</p><img src ="../../../../assets/icons/iconLoupeWhite.svg">
                    </div>
                  </router-link>
                </td>
                
              </tr>
            </tbody>
          </table>

          <nav
            v-if="!this.$route.path.startsWith('/connexion')"
            class="navlinks"
          >
            <div style="display: flex"></div>
            <div
              class="RechercherButtonContainer"
              v-if="!this.$route.path.startsWith('/connexion')"
            >
              <b-button
                to="/nouvelle-annonce"
                type="submit"
                variant="primary"
                class="mr-auto ml-auto"
                >Publier une annonce
              </b-button>
            </div>

            <span
              class="pinkhover borderHeader"
              style="cursor: pointer"
              id="popover-target-1"
              v-if="isLogged()"
            >
              Mon Compte
            </span>
            <span
              class="pinkhover borderHeader"
              style="cursor: pointer"
              v-if="!isLogged()"
              @click="connexion"
            >
              S'inscrire / se connecter
            </span>

            <b-popover
              target="popover-target-1"
              triggers="hover"
              placement="bottomleft"
              offset="100px"
              v-if="isLogged()"
            >
              <nav class="burgerLinks">
                <b-nav vertical class="moncompteContainer">
                  <b-nav-item
                    v-if="isLogged()"
                    to="/favoris"
                    @click="
                      $root.$emit('bv::hide::popover', 'popover-target-1')
                    "
                  >
                    <div class="testContainer">
                      <div class="favItemContainer"></div>
                      <span class="monCompteTextItem">FAVORIS</span>
                    </div>
                  </b-nav-item>
                  <b-nav-item
                    v-if="isLogged()"
                    class="menuLine moncompteitem"
                    to="/monCompte/mesAnnonces"
                    @click="
                      $root.$emit('bv::hide::popover', 'popover-target-1')
                    "
                  >
                    <div class="testContainer">
                      <div class="mesannoncesItemContainer"></div>
                      <span class="monCompteTextItem">MES ANNONCES</span>
                    </div>
                  </b-nav-item>
                  <b-nav-item
                    v-if="isLogged()"
                    class="menuLine moncompteitem"
                    to="/monCompte/mesInformations"
                    @click="
                      $root.$emit('bv::hide::popover', 'popover-target-1')
                    "
                  >
                    <div class="testContainer">
                      <div class="monprofilItemContainer"></div>
                      <span class="monCompteTextItem">MON PROFIL</span>
                    </div>
                  </b-nav-item>
                  <b-nav-item
                    v-if="isLogged()"
                    class="menuLine moncompteitem"
                    to="/monCompte/motdepasse"
                    @click="
                      $root.$emit('bv::hide::popover', 'popover-target-1')
                    "
                  >
                    <div class="testContainer">
                      <div class="mdpItemContainer"></div>
                      <span class="monCompteTextItem">MOT DE PASSE</span>
                    </div>
                  </b-nav-item>
                  <b-nav-item
                    v-if="isLogged()"
                    class="menuLine moncompteitem"
                    to="/monCompte"
                    @click="
                      deconnexion();
                    "
                  >
                    <div class="testContainer">
                      <div class="disconnectItemContainer"></div>
                      <span class="monCompteTextItem">SE DECONNECTER</span>
                    </div>
                  </b-nav-item>
                </b-nav>
              </nav>
            </b-popover>
          </nav>

          <nav
            v-if="
              headerState != 4 &&
              headerState != 5 &&
              headerState != 6 &&
              templateMode > 1
            "
            class="navlinks"
          >
            <router-link to="/recherche" class="bottombarSearchAlone">
              <div>
                <img
                  style="margin-top: 5px"
                  src="@/assets/pages-assets/commons/navbar/magnifier_black.svg"
                  alt="lien de la barre de navigation vers recherche"
                />
              </div>
            </router-link>
          </nav>

          <nav v-if="headerState == 5 && templateMode > 1">
            <div class="closeSearchContainer" @click="closeSearch()">
              <svg
                viewBox="0 0 16 16"
                width="2em"
                height="2em"
                focusable="false"
                role="img"
                aria-label="x"
                xmlns="http://www.w3.org/2000/svg"
                fill="#9a9da0"
              >
                <g>
                  <path
                    fill-rule="evenodd"
                    d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"
                  ></path>
                </g>
              </svg>
            </div>
          </nav>

          <b-form v-show="headerState == -1" @submit.prevent="launchSearch()">
            <InputValidatorsComponent
              left_icon_input="true"
              class="home-search"
              placeholder="Que recherchez-vous ?"
              type="text"
              name="keywords"
              rule="min:1|max:200"
              @validationState="setValidatedState"
              ref="searchInput"
            />
          </b-form>
          <div v-show="headerState != -1" class="emptyBottomSpace"></div>
        </div>
      
      </div>
    </nav>

    <div v-if="templateMode != 1" style="height: 48px"></div>
  
  </header>
</template>

<script>
import InputValidatorsComponent from "../../../shared-component/input-validators-component/input-validators-component.vue";
import { FormValidatorsMixin } from "../../../../mixins/form-validators-mixin.js";
import { SearchOptions } from "../../../../services/AnnoncesService.js";
import AuthService from "../../../../services/AuthService.js";

import router from "../../../../router";

export default {
  name: "HeaderComponent",
  components: {
    InputValidatorsComponent,
  },
  props: ["headerState", "templateMode", "route"],
  watch: {
    headerState: {
      deep: true,
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          this.updateUnderFixedHeader();
        });

        console.log("here : " + newVal + " , " + oldVal);
      },
    },
    templateMode: {
      deep: true,
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          this.updateUnderFixedHeader();
        });

        console.log("here : " + newVal + " , " + oldVal);
      },
    },
  },
  mixins: [FormValidatorsMixin],
  data() {
    return {
      lastScrollTop: 0,
      isTopBarVisible: false,
      underFixedHeaderMargin: "height:0px",
      // Form values
      form: {
        keywords: "",
      },
      // Form input field valid or not
      formValid: {
        keywords: false,
      },
    };
  },

  methods: {
    updateBackgroundHeaderColor(){
      if (this.$route.path=='/'){
       this.isTopBarVisible = false;
      } else {
      this.isTopBarVisible = true;
      }},
    
    handleScroll(){
    
      let currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollTop > 20) {
        // L'utilisateur fait défiler vers le bas
        this.isTopBarVisible = true;
        console.log("scrolling down");
      } else if (currentScrollTop < 700&&this.$route.path=='/') {
        // L'utilisateur fait défiler vers le haut
        this.isTopBarVisible = false;
      }

      this.lastScrollTop = currentScrollTop;
    
    },
    isInLogPage() {
      try {
        var container = document.getElementById("Login-Register-component");
        console.log(
          "===========================" +
            container.id +
            "==========================="
        );
        if (container != null) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    },
    isLogged() {
      return AuthService.isLogged();
    },
    hasHistory() {
      return window.history.length > 2;
    },

    closeSearch() {
      if (this.hasHistory()) {
        router.go(-1);
      } else {
        router.push("/");
      }
    },
    launchSearch(event) {
      console.info("event = " + event);
      console.info("launchSearch : " + this.$refs.searchInput.inputValue);
      let searchOptions = new SearchOptions();
      searchOptions.search = this.form.keywords;

      let request = searchOptions.getRequest();

      router.push({ path: "/categorie/" + request });
    },
    updateUnderFixedHeader() {
      this.underFixedHeaderMargin =
        "height: " + this.$refs.fixedHeader.clientHeight + "px;";
    },
    deconnexion() {
      AuthService.signOutUser().then(
        () => {
          console.info("deconnexion reussie !");
          router.push("/");
        },
        (error) => {
          this.errorMessage = error;
          console.error("deconnexion echouee : " + error);
        }
      );
    },
    connexion() {
      router.push("/connexion");
    },
  },

  mounted() {
    console.info("mounted");
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("click", this.updateBackgroundHeaderColor);
    this.updateBackgroundHeaderColor();
    this.updateUnderFixedHeader();
    this.isInLogPage();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="scss">
@import "header-component.scss";
</style>