<template>
    <b-form-group class="input-validators-component-container ml-auto mr-auto" >
        <ValidationProvider :name="name" :rules="rule" v-slot="{ errors }">
            <b-input-group-append class="mt-2 mb-0">
                <b-form-input 
                    @keyup="sendValidate()" 
                    v-model="inputValue" 
                    variant="primary" 
                    :class="getInputClass()"
                    :id="'input' + name" 
                    :type="isPassword()?passwordType:type"
                    :placeholder="placeholder"
                    :autocomplete="autocomplete"
                    :size="size?size:''">
                </b-form-input>
                <img @click="onImgClick" v-if="doWeShowIcon()" :src="isPassword()?passwordIcon:icon" alt="" :class="getIconClass()" :style="getImgAdditionalStyle()" >
            </b-input-group-append>
            <span v-if="size!='sm'" class="error-message">{{ errors[0] }}</span>
        </ValidationProvider>
    </b-form-group>
</template>

<script>
import { ValidationProvider, extend, localize, validate } from 'vee-validate';
// eslint-disable-next-line no-unused-vars
import { required, email, min, max, alpha_num, alpha_dash, regex, is, numeric, alpha, alpha_spaces} from 'vee-validate/dist/rules';
import fr from 'vee-validate/dist/locale/fr.json';
import passwordIcon from '../../../assets/icons/0166-eye.svg'
import passwordIconCrossed from '../../../assets/icons/0167-eye-crossed.svg'

// Set default language to fr
localize('fr', fr);

// !! Needed after importing a rule, can add option here too !!
extend('required', required);
extend('email', email)
extend('alpha_num', alpha_num)
extend('alpha_dash', alpha_dash)
extend('regex', regex)
extend('is', is)
extend('numeric', numeric)
extend('min', min)
extend('max', max)
extend('alpha', alpha)
extend('alpha_spaces', alpha_spaces)


export default {
    name: "InputValidatorsComponent",
    components: {
        ValidationProvider
    },
    // Take 3 props, validator rule, name of field (used in name attribute & input id)
    // and placeholder value
    props: ['type', 'rule', 'name', 'placeholder', 'autocomplete', 'icon', 'initialValue', 'left_icon_input','size'],
    data : () => {
        return {
            inputValue : '',
            passwordType: 'password',
            passwordIcon: passwordIconCrossed,
        }
    },
    computed: {
        classObject: function () {

            if (this.left_icon_input){
                return {'padding-left': '10em'};
            } 
            return null;
        }
    },
    created(){
        if (this.initialValue){
            this.inputValue = this.initialValue;
        }
        
    },
    methods: {
        doWeShowIcon(){
            if (this.icon) return true;
            if (this.isPassword()) return true;
            return false;
        },

        isPassword(){
            return this.type == "password";
        },
        getImgAdditionalStyle(){
            if (this.isPassword()){
                return "cursor: pointer;";
            }
            return "";
        },
        getIconClass(){
           
            return "input-icon-img";
        },

        getInputClass(){
            let result = [];
            if (this.doWeShowIcon()){
                result.push('input-icon');
            }

            result.push('input-validator');
   
            if (this.left_icon_input){
                result.push('left_icon_input');
            }
            return result;
        },
        sendValidate() {
            validate(this.inputValue, this.rule).then(result => {
                console.log('sendValidate to parent :', result.valid)
                this.$emit('validationState', result.valid, this.inputValue, this.name)
            })
        },
        onImgClick(){
            if (this.isPassword()){
                console.info("click sur image");
                if (this.passwordType == "text"){
                    this.passwordType = "password";
                    this.passwordIcon = passwordIconCrossed;
                } else if (this.passwordType == "password"){
                    this.passwordType = "text";
                     this.passwordIcon = passwordIcon;
                }
                
            }
            

        }
    }
};
</script>

<style lang="scss">
    @import "input-validators-component.scss";
</style>
