export const HandleFavoriteMixin = {
    data() {
        return {
        }
    },
    methods : {
        // Only handle favorite item on cards, navbarContact favorite work for item ad page separately
        // Manage favorite items, backend + toast feedback (don't forget to import toast mixin)
        emitedFavoriteState(favorite, keyIndex) {
            for (const key in this.itemsList) {
                let fId =  this.itemsList[key].firestoreId;
                if(fId == keyIndex) {
                    if(favorite == true) {
                        this.popToast(this.adFavoriteMessage, 'addition-toast')
                    }
                    else if (favorite == false) {
                        this.popToast(this.adUnfavoriteMessage, 'deletetion-toast')
                    }
                }
            }
        }
    }
}