import { db } from '../main'
import {AnnonceFromFireStore} from '../model/Annonce'
import MoteurRecherche from './MoteurRecherche.js'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

/* exported lastRef */
var lastRef;
/* exported hasMoreEntries */
var hasMoreEntries;

const postConverter = {
  toFirestore(a) {
    return {...a};
  },
  fromFirestore(
    snapshot
  ) {

  /* exported a */
    const a = new AnnonceFromFireStore();
    a.load(snapshot);
    return a;
  }
};

// Tous les paramètres sont optionnels
export class SearchOptions{
  search;
  cat;
  scat;
  region;
  dept;
  prixmin;
  prixmax;
  sort; // '1' = Plus récentes(tri par date desc) , '2' = Plus anciennes (tri par date asc), '3' = Prix croissants (tri par prix asc), '4' = Prix décroissants (tri par prix desc)

  constructor(category){
    this.cat = category;
  }

  

  loadFromQuery(query){
    this.search = query.search;
    this.cat = query.cat?parseInt(query.cat):undefined;
    this.scat = query.scat?parseInt(query.scat):undefined;
    this.region = query.region?parseInt(query.region):undefined;
    this.dept = query.dept?parseInt(query.dept):undefined;
    this.prixmin = query.prixmin?parseInt(query.prixmin):undefined;
    this.prixmax  = query.prixmax?parseInt(query.prixmax):undefined;

    MoteurRecherche.optimizeSearchOptions(this);
    return this;
  }

  getRequest(){
    

    let params = [];
    if (this.search){
      params.push("search="+this.search);
    } 
    if (this.cat){
      params.push("cat="+this.cat);
    } 
    if (this.scat){
      params.push("scat="+this.scat);
    } 
    if (this.region){
      params.push("region="+this.region);
    } 
    if (this.dept){
      params.push("dept="+this.dept);
    } 
    if (this.prixmin){
      params.push("prixmin="+this.prixmin);
    } 
    if (this.prixmax){
      params.push("prixmax="+this.prixmax);
    }
    if (params.length == 0) return "";

    let request = "?";
    let notfirst = false;
    params.forEach( p =>
      {
        if (notfirst){
          request+="&"+p;
        } else {
          notfirst = true;
          request+=p;
        }
      }
    );
    return request;


  }

}

export default {
  
  MAX_RESULT : 20,


    createAnnonce(annonce){
      return new Promise(
      (resolve, reject) => {
        db.collection('/annonces/').add({...annonce}).then(
          () => {
            resolve(true);
          }, () => {
            reject(false);
          }
        );
      }
    );
  },


   toAnnoncesArray(snapshot,search){
    var liste = [];
    if (snapshot.empty) return liste
    else {
      for (let d of snapshot.docs) {
        var anAnnonce = d.data();
        if (search){
          if (anAnnonce.containsString(search)){
            liste.push(anAnnonce);
          }
        } else {
            liste.push(anAnnonce);
        }
      }
      return liste;
    }
  },

  getAllMyAnnonces(){
    if (firebase.auth().currentUser == null){
      return new Promise((resolve) => resolve([]));
    }

    let userId = firebase.auth().currentUser.uid;
    return this.getAllUserAnnonces(userId);
  },

  getAllUserAnnonces(userId){
    let query = db.collection('annonces');
   
    query = query.where("userId",'==',userId);
    query = query.orderBy('date','desc').limit(100);
    query = query.withConverter(postConverter);
    return new Promise(
      (resolve, reject) => {
        query.get().then(
          (snapshot) => {
            if (snapshot.empty) {
              console.info("rien trouve");
            } 
            let newEntries= this.toAnnoncesArray(snapshot,undefined);
            resolve(newEntries);
          }, (error) => {
            reject(error);
            console.error("erreur de requete :" + error);
          }
        );
      }
    );
  },


  async getMultipleAnnonces(annoncesIdsArray){
    
    
   
    let remaining = annoncesIdsArray.length;
    let newEntries = [];
    let startIndex = 0;
    let endindex = remaining > 10?10:remaining;
    while(remaining > 0){
      let query = db.collection('annonces');
      query = query.where(firebase.firestore.FieldPath.documentId(),'in',annoncesIdsArray.slice(startIndex,endindex));
        
      query = query.withConverter(postConverter);

      let snapshot = await query.get();
      if (snapshot.empty) {
        console.info("rien trouve");
      }
      newEntries= newEntries.concat(this.toAnnoncesArray(snapshot,undefined));
      let treatedCount = endindex - startIndex;
      remaining-=treatedCount;
      startIndex = startIndex + treatedCount;
      endindex = remaining > 10? startIndex+10:annoncesIdsArray.length;
    }
    return newEntries;
  },

  getAllAnnonces_internal(result,maximum_result,options) {

    let query = db.collection('annonces');
    let priceRangeSet = false;
    if (options){

      if (options.cat){
        query = query.where('cat','==',options.cat);
      }

      if (options.scat){
        query = query.where('scat','==',options.scat);
      }

      if (options.region){
        query = query.where('region','==',options.region);
      }

      if (options.dept){
        query = query.where('dept','==',options.dept);
      }

      if (options.prixmin && options.prixmin > 0){
        query = query.where('prix','>=',options.prixmin);
        priceRangeSet = true;
      }

      if (options.prixmax && options.prixmax < 1500){
        query = query.where('prix','<=',options.prixmax);
        priceRangeSet = true;
      }

      if (priceRangeSet){
        if (options.sort == '4'){
          query = query.orderBy('prix','desc');
        } else {
          query = query.orderBy('prix','asc');
        }
      }
    }
    if (options.sort == undefined){
      query = query.orderBy('date','desc');
    } else if (options.sort == '1'){
      query = query.orderBy('date','desc');
    } else if (options.sort == '2'){
      query = query.orderBy('date','asc');
    } else if (options.sort == '3' && priceRangeSet == false){
      query = query.orderBy('prix','asc');
    } else if (options.sort == '4' && priceRangeSet == false){
      query = query.orderBy('prix','desc');
    } 
    query = query.limit(maximum_result);

    if (lastRef!=null){
      query = query.startAfter(lastRef);
     // console.info("on demarre a aprtir de  lastRef " + lastRef.id)
    }

    query = query.withConverter(postConverter);
    //console.info("executing query : " + query);

     return new Promise(
      (resolve, reject) => {
        query.get().then(
          (snapshot) => {
            if (!snapshot.empty) {
              lastRef = snapshot.docs[snapshot.docs.length-1];
              //console.info("on defini lastRef a " + lastRef.id)
              if (snapshot.size >= maximum_result) hasMoreEntries = true;
            } else {
              console.info("rien trouve");
              hasMoreEntries = false;
            }
            var newEntries= this.toAnnoncesArray(snapshot,options?options.search:undefined);
            result.push(...newEntries);
           console.info("pushing entries to result...");
            resolve(newEntries);
          }, (error) => {
            reject(error);
            console.error("erreur de requete :" + error);
          }
        );
      }
    );
  },


  getAllAnnoncesRec(result,maximum_result,options)  {
    return this.getAllAnnonces_internal(result,maximum_result,options).then(
      (newEntries) => {
        if (newEntries.length < maximum_result && hasMoreEntries){
          this.getAllAnnoncesRec(result,maximum_result-newEntries.length,options);
        } else {
          
          console.log("Fin recherche");
        }
      }, () => {
        
      }
    );
  },



  getAllAnnonces(result,reset,options)  {
    if (reset) {
      lastRef = null;
      hasMoreEntries = false;
    }
    
    let tmpResult = [];

    return this.getAllAnnoncesRec(tmpResult,options && options.search?200:this.MAX_RESULT,options).then(()=>{
       tmpResult = tmpResult.sort((a,b)=>b.searchScore - a.searchScore);
       result.push(...tmpResult);
    });

    
  },

  

  getAnnonce(fireStoreID){
    return new Promise(
      (resolve, reject) => {
      db.collection('annonces').doc(fireStoreID).withConverter(postConverter).get().then( (snapshot) => {
            resolve(snapshot.data());
      }, (error) => {
        reject(error);
        console.error("erreur de requete :" + error);
      });
    });
  },

  hasMoreAnnonces(){
    return hasMoreEntries;
  },


  deleteAnnonce(annonceId) {
    return new Promise(
      (resolve, reject) => {
        db.collection('/annonces/').doc(annonceId).delete().then(
          () => {
            resolve();
          }, (error) => {
            reject(error);
          }
        );
      }
    );
  },
}