import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueCookies from 'vue-cookies'
import {BootstrapVue,BootstrapVueIcons} from 'bootstrap-vue'
// Custom import of boostrap sccs to overload it with custom classes
import './styles/bootsrap_style_overload.scss'
import FrenchMonths from './model/FrenchMonths.js'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import './registerServiceWorker'
//import { firestorePlugin } from 'vuefire'




Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueCookies)


Vue.filter('formatDate', function(value) {


  if (value) {

   return value.getDate()+" "+FrenchMonths.french_months[value.getMonth()]+ " " + value.getFullYear();
  }
});

firebase.initializeApp({
  apiKey: "AIzaSyBUMyd8aO6MxSdrzdZsoNJktRxVot6gWe4",
  authDomain: "showroombaby-79e5a.firebaseapp.com",
  databaseURL: "https://showroombaby-79e5a.firebaseio.com",
  projectId: "showroombaby-79e5a",
  storageBucket: "showroombaby-79e5a.appspot.com",
  messagingSenderId: "345769697720",
  appId: "1:345769697720:web:a1c709aa4248c6a6f82f4d",
  measurementId: "G-54LN5HNNET"
})
//Vue.use(firestorePlugin)

export const db = firebase.firestore()


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')