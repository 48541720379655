<script>
import VerticalAnnonce from '../../shared-component/vertical-annonce/vertical-annonce.vue'

import {ToastMixin} from '../../../mixins/toast-mixin.js'
import {HandleFavoriteMixin} from '../../../mixins/handle-favorite-mixin.js'

export default {
    name: "AdGrid",
    components: {
        VerticalAnnonce
    },
    mixins: [ToastMixin, HandleFavoriteMixin],
    props: ['optimize','templateMode'],
    data() {
        return {
            itemsList : [],
            displayedItemList:null,
            missingItemList:[],
        }
    },
    created(){
        this.itemsList= [];
    },

    mounted() {
        this.$nextTick(() => {
             window.addEventListener('resize', this.onResize);
        })
        //this.onResize();
    },

    methods: {
      
        getCardWidth(){
            return (this.getScreenWidth()/ this.getColumnCounts()) -12;
        },
        getScreenWidth(){
            if (window.innerWidth > 1000) return 1000;
            return window.innerWidth;
        },
        getCardHeight(){
            return this.getCardWidth() * 1.27;
        },

        getColumnCounts(){
            if (window.innerWidth > 914){
                return 4;
            } else if (window.innerWidth > 734){
                return 4;
            } else if (window.innerWidth > 554){
                return 3;
            } else if (window.innerWidth > 374){
                return 2;
            } else {
                return 2;
            }
        },

        calculateMissingCards(){
            let columns = this.getColumnCounts();
            let count = this.itemsList.length;
            let reste = (count%columns);
            if (reste == 0) return 0;
            return columns - reste;
        },

        setAnnonces(annoncesList){
            this.itemsList = annoncesList;
            this.setOptimizedAnnoncesList();
        },

        setOptimizedAnnoncesList(){
            this.missingItemList = [];
            let missing = this.calculateMissingCards();
            if (missing > 0){
                for(let i=0;i < missing;i++){
                    this.missingItemList.push("missing");
                }
            }
            this.displayedItemList = this.itemsList; 
        }
        ,onResize() {
            this.setOptimizedAnnoncesList();
        },
        
    }
    
};
</script>

<style lang="scss">
    @import "ad-grid.scss";
</style>
<template src="./ad-grid.html"></template>