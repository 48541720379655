<template>
    <div class="modal-alert-component-container">
        <b-modal v-model="showed"
            id="Alert-modal" 
            :centered="true" 
            footer-class="centered-footer"
            :hide-header="true"
            ok-title="Oui"
            cancel-title="Non"
            cancel-variant="outline-secondary"
            title="AlertModal"
            @ok="userValidation"
            @cancel="userCancel">
            <p class="">{{ modalMessage }}</p>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "ModalAlertComponent",
    components: {
    },
    props: ['modalMessage','okFunc','cancelFunc'],
    data() {
        return {
            showed:false,
        }
    },
    methods: {
        showModal() {
            this.showed = true;
        },
        userValidation() {
            console.log('confirmed modal action')
            this.okFunc();
             this.showed = false;
        },
        userCancel() {
            console.log('cancelled modal action')
            this.cancelFunc();
            this.showed = false;
        }
    }
};
</script>

<style lang="scss">
    @import "modal-alert-component.scss";
</style>