import { db } from '../main'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

export default {

  sendMessage(message){
    
    return new Promise(
     (resolve, reject) => {
       db.collection('/messages/').doc(message.userDestId).set({...message}).then(
         () => {
           resolve();
         }, (error) => {
           reject(error);
         }
       );
     }
   );
  },

  sendContactUsMessage(message){
    
    return new Promise(
     (resolve, reject) => {
       db.collection('/contactus/').doc(message.authorEmail).set({...message}).then(
         () => {
           resolve();
         }, (error) => {
           reject(error);
         }
       );
     }
   );
  },


}