<template>
    <div v-if="showPopup()" id="cookiesAccept">
        
               <span class="textContainer" v-if="templateMode==1">
                   <div class="containerCookies" ><img class="cookies" src="@/assets/icons/cookies.png" ></div>
                <span>Coucou c'est nous les cookies ! Ce site web utilise des cookies afin d'optimiser votre expérience de navigation. <router-link style="text-decoration: underline !important;" to="/cookies">En savoir plus</router-link> </span>
            </span>
            <div class="acceptContainer" v-if="templateMode==1" style="padding-right:13px;">

                <b-button @click="jaiCompris()" variant="primary" class="mr-auto ml-auto acceptButton" >J'ai compris !</b-button>

            </div>
       


            <table v-if="templateMode>1">
                <tbody>
                    <tr class="containerCookies" > <td><img class="cookies" src="@/assets/icons/cookies.png" > </td></tr>
                    <tr>
                    <td>  <span class="textContainer" style=" font-size: 0.95em;">
                                    <span>Ce site web utilise des cookies afin d'optimiser votre expérience de navigation. <router-link style="text-decoration: underline !important;" to="/cookies">En savoir plus</router-link> </span>
                                </span></td>
                              
                    </tr>
                    <tr>
                    <td> <div class="acceptContainer" style="margin-top:16px;">

                                    <b-button @click="jaiCompris()" variant="primary" class="mr-auto ml-auto acceptButton">J'ai compris !</b-button>

                                </div></td>
                    </tr>
                </tbody>
            </table>
             
   
       
         

    </div>

    
</template>

<script>
export default {
    name: "CookiesAccept",
    props: ['templateMode','route'],
     data : () => {
        return {
            closed: false,
        }
    },     
  
    methods :{
        jaiCompris() {
            console.log('jaiCompris');
            this.closed =  true;
            this.$cookies.set("cookies_accepted","true",24 * 60 * 60); // cachée pour 24 heures

        },
        showPopup(){
            if (this.closed) return false;

            return this.$cookies.get("cookies_accepted") !="true" && this.route == '/';
        }

     }
};
</script>

<style lang="scss">
    @import "cookiesAccept.scss";
</style>