import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

export default {
  uploadPhoto(annonce,photoNumber,file) {
    return new Promise(
      (resolve, reject) => {
        const fileName = annonce.getPhotoFileName(photoNumber);
        const upload = firebase.storage().ref()
          .child('images/' + fileName).put(file);
        upload.on(firebase.storage.TaskEvent.STATE_CHANGED,
          () => {
            console.log('Chargement…');
          },
          (error) => {
            console.log('Erreur de chargement ! : ' + error);
            reject();
          },
          () => {
            resolve(upload.snapshot.ref.getDownloadURL());
          }
        );
      }
    );
  },
  resizeAnduploadPhoto(annonce,photoNumber,file,width,height) {
    return this.resizeImage(file,width,height).then((resizeImageFile)=>
      {
        return this.uploadPhoto(annonce,photoNumber,resizeImageFile);
      });
  },

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    },


  resizeImage(imageFile,width,height){
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = (readerEvent) => {
          let image = new Image();
              image.onload = ()=> {
                  // Resize the image
                  let canvas = document.createElement('canvas');
                  canvas.width = width;
                  canvas.height = height;
                  let ctx = canvas.getContext('2d');
                  //ctx.drawImage(image, 0, 0, width, height);
                  this.drawImageToFillCover(image,ctx,canvas);
                  let dataUrl = canvas.toDataURL('image/jpeg');
                  resolve(this.dataURLtoFile(dataUrl,'image.jpeg'));
              }
              image.onerror = (ev)=>{
                reject(ev);
              }
              image.src = readerEvent.target.result;
      }
      reader.readAsDataURL(imageFile);
    });
   },

   drawImageToFillCover(img,ctx,canvas){
    // get the scale
    let scale = Math.max(canvas.width / img.width, canvas.height / img.height);
    // get the top left position of the image
    let x = (canvas.width / 2) - (img.width / 2) * scale;
    let y = (canvas.height / 2) - (img.height / 2) * scale;
    ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
  },


  uploadProfilePhoto(user,file) {
    return new Promise(
      (resolve, reject) => {
        const fileName = user.getPhotoFileName();
        const upload = firebase.storage().ref()
          .child('profilesPhotos/' + fileName).put(file);
        upload.on(firebase.storage.TaskEvent.STATE_CHANGED,
          () => {
            console.log('Chargement…');
          },
          (error) => {
            console.log('Erreur de chargement ! : ' + error);
            reject();
          },
          () => {
            resolve(upload.snapshot.ref.getDownloadURL());
          }
        );
      }
    );
  },

  resizeAndUploadProfilePhoto(user,file,width,height) {
    return this.resizeImage(file,width,height).then((resizeImageFile)=>
      {
        return this.uploadProfilePhoto(user,resizeImageFile);
      });
  },

  deletePhoto(annonce,photoNumber) {
    const fileName = annonce.getPhotoFileName(photoNumber);
    const storageRef = firebase.storage().ref().child('images/' + fileName);
    storageRef.delete().then(
      () => {
        console.log('Photo removed!');
        annonce.setPhotoDeleted(photoNumber);
      },
      (error) => {
        console.log('Could not remove photo! : ' + error);
      }
    );
  },

  getDownloadURL(annonce,photoNumber){
    const fileName = annonce.getPhotoFileName(photoNumber);
    const storageRef = firebase.storage().ref().child('images/' + fileName);
    return storageRef.getDownloadURL();
  },

  getProfilePhotoDownloadURL(user){
    const fileName = user.getPhotoFileName();
    const storageRef = firebase.storage().ref().child('profilesPhotos/' + fileName);
    return storageRef.getDownloadURL();
  },

  deleteProfilePhoto(user) {
    const fileName = user.getPhotoFileName();
    const storageRef = firebase.storage().ref().child('profilesPhotos/' + fileName);
    return storageRef.delete().then(
      () => {
        console.log('Photo removed!');
      },
      (error) => {
        console.log('Could not remove photo! : ' + error);
      }
    );
  },

}