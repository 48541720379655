import { db } from '../main'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

import { User, ProUser } from '../model/User';

export default {

 
  doesUserExists(userFirestoreId){
    return new Promise(
      (resolve, reject) => {
        db.collection('users').doc(userFirestoreId).get().then(
          (snapshot) => {
            if (snapshot.exists) {
              resolve(true);
            } else {
              resolve(false);
            }
          }, (error) => {
            reject(error);
          }
        );
      }
    );
  },

  setUser(user){
    user.firestoreId = firebase.auth().currentUser.uid;
    return new Promise(
     (resolve, reject) => {
       db.collection('/users/').doc(user.firestoreId).set({...user}).then(
         () => {
           resolve();
         }, (error) => {
           reject(error);
         }
       );
     }
   );
  },



  deleteUser(user, password) {
    let fireBaseUser = firebase.auth().currentUser;
    let providerId = fireBaseUser.providerData[0].providerId;
    console.info("providerId = " + providerId);
    user.firestoreId = fireBaseUser.uid;

    if (providerId == 'google.com'){
      console.info("Deleting Google account");
      return this.deleteSpecialProviderUser(new firebase.auth.GoogleAuthProvider(),user);
    } else if (providerId == 'facebook.com'){
      console.info("Deleting Facebook account");
      return this.deleteSpecialProviderUser(new firebase.auth.FacebookAuthProvider(),user);
    } else {
      return new Promise(
        (resolve, reject) => {
          firebase.auth()
          .signInWithEmailAndPassword(user.email, password)
          .then(function() {
            db.doc('/users/' + user.firestoreId).delete().then(
              () => {
                resolve();
              }, (error) => {
                reject(error);
              });
          }).catch(function(err){
              //Do something
              reject("Vérification du mot de passe échouée, " + err);
          });
        });
    }

    
  },

  deleteSpecialProviderUser(provider,user){
    
    return new Promise(
      (resolve, reject) => {
        firebase.auth().signInWithPopup(provider)
        .then(function() {
          db.doc('/users/' + user.firestoreId).delete().then(
            () => {
              resolve();
            }, (error) => {
              reject(error);
            });
        }).catch(function(err){
            //Do something
            reject("Vérification du mot de passe échouée, " + err);
        });
      });
  },


  getSingleUser(userFirestoreId)  {
    return new Promise(
      (resolve, reject) => {
        db.collection('users').doc(userFirestoreId).get().then(
          (snapshot) => {
       
            if (snapshot.get("pro")){
              resolve(new ProUser().load(snapshot));
            } else {
              resolve(new User().load(snapshot));
            }
            
          }, (error) => {
            reject(error);
          }
        );
      }
    );
  }
}