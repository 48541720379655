<template>
  <div id="footer">
    <div id="FooterContainer">
      <div
        v-if="templateMode == 1 && !this.$route.path.startsWith('/connexion')"
        style="padding-top: 30px; margin-bottom: 20px"
      >
        <table width="100%">
          <tbody>
            <tr>
              <td class="titleHeader">À propos de nous</td>
              <td class="titleHeader">Informations légales</td>
              <td class="titleHeader">Suivez-nous</td>
              <td class="titleHeader">Recevez notre newsletter</td>
            </tr>
            <tr>
              <td><br /></td>
              <td><br /></td>
              <td><br /></td>
              <td><br /></td>
            </tr>
            <tr>
              <td>
                <router-link to="/contactus" class="itemHeader pinkhover"
                  >Nous contacter</router-link
                >
              </td>
              <td>
                <router-link to="/cgu" class="itemHeader pinkhover"
                  >Conditions Générales d'utilisation</router-link
                >
              </td>
              <td>
                <router-link to="/" class="itemHeader pinkhover"
                  ><img class="imageLogo"
                    src="@/assets/icons/facebook.svg"
                    alt="iconFacebook" /></router-link
                ><router-link
                  to="/"
                  class="itemHeader pinkhover"
                  style="margin-left: 20px"
                  ><img class="imageLogo" src="@/assets/icons/insta.svg" alt="iconFacebook"
                /></router-link>
              </td>
              <td rowspan="4" valign="top">
                <b-form>
                  <b-form-group>
                    <table
                      style="max-width: 308px; margin: 0 auto"
                      width="100%"
                    >
                      <tr>
                        <td>
                          <InputValidatorsComponent
                            ref="emailInput"
                            autocomplete="email"
                            type="email"
                            placeholder="Adresse mail"
                            name="email"
                            rule="email"
                            @validationState="setValidatedState"
                            size="sm"
                          />
                        </td>
                        <td>
                          <b-button
                            v-on:click="saveEmail()"
                            size="sm"
                            class="mb-2"
                            style="background-color: #e7b9b7; border: none"
                            :disabled="!emailValid"
                            >M'inscrire<b-icon
                              v-if="savingEmailForNewsletter"
                              icon="arrow-counterclockwise"
                              animation="spin-reverse"
                              font-scale="1"
                            ></b-icon
                          ></b-button>
                        </td>
                      </tr>
                    </table>
                  </b-form-group>
                </b-form>
              </td>
            </tr>
            <tr>
              <td>
                <router-link to="/" class="itemHeader pinkhover"></router-link>
              </td>
              <td>
                <router-link to="/cookies" class="itemHeader pinkhover"
                  >Cookies</router-link
                >
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <router-link to="/" class="itemHeader pinkhover"></router-link>
              </td>
              <td>
                <router-link to="/mentionslegales" class="itemHeader pinkhover"
                  >Mentions légales</router-link
                >
              </td>
              <td>
                <router-link to="/" class="itemHeader pinkhover"></router-link>
              </td>
            </tr>

            <tr>
              <td>
                <router-link to="/" class="itemHeader pinkhover"></router-link>
              </td>
              <td>
                <router-link to="/" class="itemHeader pinkhover"></router-link>
              </td>
              <td>
                <router-link to="/" class="itemHeader pinkhover"></router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="templateMode > 1 && this.route == '/'"
        style="margin-bottom: -12px"
      >
        <b-form>
          <b-form-group style="margin: 0 auto">
            <table width="100%">
              <tr>
                <td colspan="2">
                  <div class="titleHeader" style="margin-bottom: -5px">
                    Recevez notre newsletter
                  </div>
                </td>
              </tr>

              <tr>
                <td width="82%">
                  <InputValidatorsComponent
                    ref="emailInput"
                    autocomplete="email"
                    type="email"
                    placeholder="Adresse mail"
                    name="email"
                    rule="email"
                    @validationState="setValidatedState"
                    size="sm"
                  />
                </td>
                <td align="left" width="8%">
                  <b-button
                    v-on:click="saveEmail()"
                    size="sm"
                    class="mb-2"
                    variant="primary"
                    :disabled="!emailValid"
                    >Ok<b-icon
                      v-if="savingEmailForNewsletter"
                      icon="arrow-counterclockwise"
                      animation="spin-reverse"
                      font-scale="1"
                    ></b-icon
                  ></b-button>
                </td>
              </tr>
            </table>
            <table width="100%">
    <tbody>
        <!-- À propos de nous -->
        <tr>
            <td class="titleHeader">À propos de nous</td>
        </tr>
        <tr>
            <td>
                <router-link to="/contactus" class="itemHeader pinkhover">Nous contacter</router-link>
            </td>
        </tr>
        <tr>
            <td><router-link to="/" class="itemHeader pinkhover"></router-link></td>
        </tr>

        <!-- Informations légales -->
        <tr>
            <td class="titleHeader">Informations légales</td>
        </tr>
        <tr>
            <td>
                <router-link to="/cgu" class="itemHeader pinkhover">Conditions Générales d'utilisation</router-link>
            </td>
        </tr>
        <tr>
            <td>
                <router-link to="/cookies" class="itemHeader pinkhover">Cookies</router-link>
            </td>
        </tr>
        <tr>
            <td>
                <router-link to="/mentionslegales" class="itemHeader pinkhover">Mentions légales</router-link>
            </td>
        </tr>
        
        <!-- Suivez-nous -->
        <tr>
            <td class="titleHeader">Suivez-nous</td>
        </tr>
        <tr>
            <td>
                <router-link to="/" class="itemHeader pinkhover">
                    <img class="imageLogo" src="@/assets/icons/facebook.svg" alt="iconFacebook" />
                </router-link>
                <router-link to="/" class="itemHeader pinkhover" style="margin-left: 20px">
                    <img class="imageLogo" src="@/assets/icons/insta.svg" alt="iconInstagram" />
                </router-link>
            </td>
        </tr>
        
        <!-- Recevez notre newsletter -->
        <tr>
            <td class="titleHeader">Recevez notre newsletter</td>
        </tr>
        <tr>
            <td>
                <b-form>
                    <b-form-group>
                        <table style="max-width: 308px; margin: 0 auto" width="100%">
                            <tr>
                                <td>
                                    <InputValidatorsComponent ref="emailInput" autocomplete="email" type="email" placeholder="Adresse mail" name="email" rule="email" @validationState="setValidatedState" size="sm" />
                                </td>
                                <td>
                                    <b-button v-on:click="saveEmail()" size="sm" class="mb-2" style="background-color: #e7b9b7; border: none" :disabled="!emailValid">
                                        M'inscrire
                                        <b-icon v-if="savingEmailForNewsletter" icon="arrow-counterclockwise" animation="spin-reverse" font-scale="1"></b-icon>
                                    </b-button>
                                </td>
                            </tr>
                        </table>
                    </b-form-group>
                </b-form>
            </td>
        </tr>
    </tbody>
</table>

          </b-form-group>
        </b-form>
      </div>

      <div class="horizontalLineSeparator"></div>
      <div class="showroombaby">
        <img height="50px" src="@/assets/newBaniere.png" />
      </div>
      
      
      <!--<div class="links">
             <div><b-link href="/cgu">CGU</b-link></div>
        </div>
        -->
    </div>
    <ModalInfoComponent
      id="informationOnly"
      ref="informationOnly"
      :modalMessage="informationText"
      :okFunc="okInformationOnly"
    />
  </div>
</template>

<script>
import InputValidatorsComponent from "../../../shared-component/input-validators-component/input-validators-component.vue";
import { FormValidatorsMixin } from "../../../../mixins/form-validators-mixin.js";
import NewsLetterService from "../../../../services/NewsLetterService.js";
import ModalInfoComponent from "../../../shared-component/modal-info-component/modal-info-component.vue";

export default {
  name: "HeaderComponent",
  props: ["headerState", "templateMode", "route"],
  components: {
    InputValidatorsComponent,
    ModalInfoComponent,
  },
  mixins: [FormValidatorsMixin],
  data() {
    return {
      // Form values
      informationText: "",
      form: {
        email: "",
      },
      formValid: {
        email: false,
      },
      emailValid: false,
      savingEmailForNewsletter: false,
    };
  },
  methods: {
    afterSetValidatedState(validated, inputValue, fieldName) {
      console.info(
        "afterSetValidatedState(" +
          validated +
          "," +
          inputValue +
          "," +
          fieldName +
          ")"
      );
      this.emailValid = validated && inputValue.length >= 6;
    },

    saveEmail() {
      console.info("saveEmail : " + this.form.email);
      this.savingEmailForNewsletter = true;
      NewsLetterService.saveEmail(this.form.email).then(
        () => {
          this.informationText = "Votre email a bien été enregistré. Merci.";
          this.savingEmailForNewsletter = false;
          this.$refs.emailInput.inputValue = "";
          this.form.email = "";
          this.$nextTick(() => {
            this.$refs.informationOnly.showModal();
          });
        },
        () => {
          this.informationText = "Votre email est déjà enregistré.";
          this.savingEmailForNewsletter = false;
          this.$refs.emailInput.inputValue = "";
          this.form.email = "";
          this.$nextTick(() => {
            this.$refs.informationOnly.showModal();
          });
        }
      );
    },
    okInformationOnly() {},
  },
};
</script>

<style lang="scss">
@import "footer.scss";
</style>