<script>
import {HandleFavoriteMixin} from '../../../mixins/handle-favorite-mixin.js'
import AnnoncesService, { SearchOptions } from '../../../services/AnnoncesService.js'
//import VerticalAnnonce from '../../shared-component/vertical-annonce/vertical-annonce.vue'
import AdGrid from '../../shared-component/ad-grid/ad-grid.vue'
export default {
    name: "HomePage",
    components: {
        /*VerticalAnnonce,*/AdGrid
    },
    mixins: [HandleFavoriteMixin],
    props: ['templateMode'],
    data() {
        return {
            dernieresAnnoncesList :[],
            zoom: 1.5,
        }
    },
    created() {
        this.$emit('headerState', 1)
        this.launchSearch();
    },
     /*mounted() {
        this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
        })
        this.onResize();
    },*/
    methods: {
        launchSearch(){
            console.info("launchSearch");
            let searchOptions = new SearchOptions();
            AnnoncesService.getAllAnnonces(this.dernieresAnnoncesList,true,searchOptions).then(()=>{
                this.rechercheEnCours=false
                this.$refs.adGrid.setAnnonces(this.dernieresAnnoncesList);
            });
        },
        getBanniereStyle(){
            if (this.templateMode==1){
                return "position:absolute;left:0px;width:100%";
            } else {
                return "height:30vh";
            }
        },
        getDerniereAnnonceStyle(){
            if (this.templateMode==1){
                return "";
            } else {
                return "font-size:1.2em";
            }
        },

        getDernieresAnnoncesStyle(){
             if (this.templateMode==1){
                return " padding-top:700px";
            }else {
                return "";
            }
        },
        getSloganStyle(){
            if (this.templateMode==1){
                
                return "font-size:5em;font-family:'Montserrat-Regular.ttf', sans-serif;";
            }else {
                return "font-size:1.3em;height:80%;overflow:hidden";
            }
        },
        getSloganContainerStyle(){
            if (this.templateMode!=1){
                
                return "overflow:hidden;margin-top:7%; padding-top:0%; padding-left:5%";
            }
        },
        getFormContainerClass(){
            if (this.templateMode==1){
                return "form-container banniere-background-pc";
            }else {
                return "form-container banniere-background";
            }
            
        }




        /*onResize() {
          this.zoom = this.calculateZoom(window.innerHeight);
          this.zoom = 1;
        },

        calculateZoom(h){
            if (h >= 1200){
                return (1.0 * (h) / 600.0)
            }if (h >= 1100){
                return (1.0 * (h) / 620.0)
            }else if (h >= 1000){
                return (1.0 * (h) / 630.0)
            }else if (h >= 900){
                return (1.0 * (h) / 655.0)
            }else if (h >= 800){
                return (1.0 * (h) / 680.0)
            } else if(h >= 650){
                 return (1.0 * (h) / 640.0)
            } else if(h >= 600){
                 return (1.0 * (h) / 680.0)
            } else {
                return (1.0 * (h) / 740.0)
            }

           
        }*/

    }
};
</script>

<style lang="scss">
    @import "home-page.scss";
</style>

<template src="./home-page.html"></template>