import CategoriesList from '../assets/csv/categories/categories_list.csv'
import SousCategoriesList from '../assets/csv/categories/souscategories_list.csv'
import Keywords from '../assets/csv/moteurderecherche/keywords.csv'
import DepartementsList from '../assets/csv/regions_dpts_communes/departements-france_encoded.csv'
import BrandList from '../assets/csv/marques/brands.csv'
import RegionsList from '../assets/csv/regions_dpts_communes/regions-france_encoded.csv'

import RegionsGpsPositions from '../assets/csv/regions_dpts_communes/regionsPositionGPS.csv'
import DepartementsGpsPositions from '../assets/csv/regions_dpts_communes/departementsPositionGPS.csv'

export class DeptGPSPotision{
    dept;
    long;
    lat;
    constructor(de, lo, la) {
        this.dept = de;
        this.long = lo;
        this.lat = la;
    }
}

export class RegionGPSPotision{
    region;
    long;
    lat;
    constructor(re, lo, la) {
        this.region = re;
        this.long = lo;
        this.lat = la;
    }
}

export class Brand{
    cat;
    value;
    text;
    constructor(c, v, t) {
        this.cat = c;
        this.value = v;
        this.text = t;
    }
}

export class Categorie {
    value;
    text;
    image;
    constructor(k, n, i) {
        this.value = k;
        this.text = n;
        this.image = i;
    }
}

export class SubCategorie {
    cat;
    value;
    text;
    constructor(c, k, n) {
        this.cat = c;
        this.value = k;
        this.text = n;
    }
}

export class MotCle {
    mot;
    cat;
    scat;
    constructor(m, c, s) {
        this.mot = m;
        this.cat = c;
        this.scat = s;
    }
  }

export class EtatProduit {
    value;
    text;
    constructor(v, t) {
        this.value = v;
        this.text = t;
    }
  }

export class Departement {
    region;
    regiontext;
    value;
    text;
    constructor(r, rt,v, t) {
        this.region = r;
        this.regiontext = rt;
        this.value = v;
        this.text = t;
    }
  }

  export class Region {
    value;
    text;
    constructor(v, t) {
        this.value = v;
        this.text = t;
    }
  }


export default {
    initialized : undefined,
    categories : [],
    souscategories : [],
    keywords : [],
    departements : [],
    departementsByCode : new Map(),
    regionsByCode : new Map(),

    categorieByValue : new Map(),
    souscategorieByValue : new Map(),
    brandByValue : new Map(),
    etatByCode : new Map(),

    deptGPSPositions : null,
    positionByRegCode : new Map(),
    positionByDeptCode : new Map(),

    etatsProduits: [
        new EtatProduit(1,'Neuf'),
        new EtatProduit(2,'Très bon état'),
        new EtatProduit(3,'Bon état'),
        new EtatProduit(4,'Etat satifaisant'),
      ],
  

    init(){
        if (this.initialized) return ;

        this.etatsProduits.forEach((e) =>{
            this.etatByCode.set(e.value,e);
            //console.info("Etat : value = " + e.value + " , text = " + e.text);
        });



        for (const key in CategoriesList) {
            let c = CategoriesList[key];
    
            let o = new Categorie(c.key,c.displayName,c.imageDeFond);
            this.categories.push(o);
            this.categorieByValue.set(o.value,o);
           // console.info("newcat = " + o.value + "," + o.text + "," + o.image);
        }
        this.optionsCategorie = [{value: null, text: 'Sélectionnez une catégorie', disabled: true},...this.categories]

        for (const key in SousCategoriesList) {
            let o = new SubCategorie(SousCategoriesList[key].cat,SousCategoriesList[key].key,SousCategoriesList[key].displayName);
            this.souscategories.push(o);
            this.souscategorieByValue.set(o.value,o);
            //console.info("newscat = " + o.cat +"," + o.key + "," + o.displayName);
        }
        for (const key in Keywords) {
            let o = new MotCle(Keywords[key].mot,Keywords[key].cat,Keywords[key].scat);
            this.keywords.push(o);
            //console.info("Keyword = " + o.mot +"," + o.cat + "," + o.scat);
        }

        for (const key in DepartementsList) {
            let o = new Departement(DepartementsList[key].code_region,DepartementsList[key].nom_region,DepartementsList[key].code_departement,DepartementsList[key].nom_departement);
            this.departements.push(o);
            this.departementsByCode.set(o.value,o);
            //console.info("Departement = " + o.region +"," + o.value + "," + o.text);
        }

        for (const key in RegionsList) {
            let o = new Region(RegionsList[key].code_region,RegionsList[key].nom_region);
            this.regionsByCode.set(o.value,o);
            //console.info("Region = " + o.value +"," + o.text);
        }

        for (const key in BrandList) {
            let o = new Brand(BrandList[key].categorie,BrandList[key].key,BrandList[key].marque);
            this.brandByValue.set(o.value,o);
            //console.info("Brand = " + o.cat +"," + o.value + "," + o.text);
        }

        

        this.initialized = true;
    },

    getCategories(){
        return this.categories;
    },

    getSousCategories(){
        return this.souscategories;
    },

    getKeyWords(){
        return this.keywords;
    },
    
    getEtatsProduits(){
        return this.etatsProduits;
    },

    getDepartementByCode(code){
        return this.departementsByCode.get(code);
    },

    getDepartementByPostalCode(postalCode){
        let p = "" + postalCode;
        let count = 2;
        if (p.startsWith("97")){
            count = 3;
        }
        
        return this.getDepartementByCode(parseInt(p.substr(0,count)));
    },

    getRegionByCode(code){
        return this.regionsByCode.get(code);
    },

    getCategoryByValue(catValue){
        return this.categorieByValue.get(catValue)
    },
    getDescriptionByValue(catvalue){
        console.log('======================'+catvalue+'===================================')
    },

    getSousCategoryByValue(scatValue){
        return this.souscategorieByValue.get(scatValue)
    },

    getBrandByValue(v){
        return this.brandByValue.get(v);
    },

    getBrandNameByValue(v){
        let b = this.brandByValue.get(v);
        if (b){
            return b.text;
        } else {
            return undefined;
        }
    },

    getEtatByCode(v){
        return this.etatByCode.get(v);
    },

    getGPSForDeptCode(c){
        this.loadGPSPositions();
        return this.positionByDeptCode.get(c);
    },
    getGPSForRegionCode(c){
        this.loadGPSPositions();
        return this.positionByRegCode.get(c);
    },

    loadGPSPositions(){
        if (this.deptGPSPositions ==null){
            this.deptGPSPositions = [];
            for (const key in DepartementsGpsPositions) {
                let line = DepartementsGpsPositions[key];
                let o = new DeptGPSPotision(line.code_departement,line.long,line.lat);
                //console.info("dept : " + o.dept + " long = " + o.long + " , lat = " + o.lat);
                this.positionByDeptCode.set(o.dept,o);
                
            }

            for (const key in RegionsGpsPositions) {
                let line = RegionsGpsPositions[key];
                let o = new RegionGPSPotision(line.code_region,line.long,line.lat);
                //console.info("dept : " + o.dept + " long = " + o.long + " , lat = " + o.lat);
                this.positionByRegCode.set(o.region,o);
                
            }
            
        }

       

    },
      // Converts numeric degrees to radians
      toRad(Value) 
      {
          return Value * Math.PI / 180;
      },
    //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
    calcCrow(alat1, lon1, alat2, lon2) 
    {
        var R = 6371; // km
        var dLat = this.toRad(alat2-alat1);
        var dLon = this.toRad(lon2-lon1);
        var lat1 = this.toRad(alat1);
        var lat2 = this.toRad(alat2);

        var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        var d = R * c;
        return d;
        },

  
    findDepartementOfGpsPosition(long,lat){
        this.loadGPSPositions();
        let best = 99999999999;
        let result = null;

        this.deptGPSPositions.forEach((x) => {
            

            let dist = this.calcCrow(lat,long,x.lat,x.long);
            //console.info("dist = " + dist);
            if (dist < best){
                best = dist;
                result = x;
            }
        });

        return result;
    },
    



   
}
