import Const from "../model/Const.js"

export class CatAndScatFound{
    cat; // : number;
    subcat;// : number;
    newsearch;// : string;
  }
  export class DepartementFound{
    dept;//: number;
    newsearch;//:string;
  }

export default {

     optimizeSearchOptions(options){
            // Optimisation recherche, on cherche dans la requete de recherche si on trouve une catégorie , sous catégorie, etc...
        if (options && options.search){
          if (!options.cat){
            console.info('pas de categorie renseignee, on cherche une categorie et sous categorie...');
            let tmp = this.findCategorieIntoString(options.search);
            options.cat = tmp.cat;
            options.scat= tmp.subcat;
            options.search = tmp.newsearch;
            console.info('categorie trouvee : cat='+options.cat + " , subcat = " + options.scat + " , newsearch = " + options.search);
          }

          if (!options.region){
            let deptFound = this.findDepartementIntoString(options.search);
            console.info('departement trouve : ' + deptFound.dept);
            options.dept = deptFound.dept;
            options.search = deptFound.newsearch;
          }
        }
     },

     findCategorieIntoString(search) {
        const result = new CatAndScatFound();
        const searchLowered = search.toLowerCase();
        result.newsearch = search;
        for(let i= 0; i < Const.getCategories().length;i++){
          let c = Const.getCategories()[i];
          let words = c.text.split(" / ");
          for (let index = 0; index < words.length; index++) {
            const w = words[index].toLowerCase();
            if (searchLowered.indexOf(w)!= -1){
              result.cat = c.key;
              result.newsearch = result.newsearch.replace(w,'');
            }
          }
        }
  
  
        for(let i = 0; i < Const.getSousCategories().length;i++){
          let s = Const.getSousCategories()[i];
          let words = s.text.split(" / ");
          for (let index = 0; index < words.length; index++) {
            const w = words[index].toLowerCase();
            if (searchLowered.indexOf(w)!= -1){
              result.subcat = s.key;
              result.newsearch = result.newsearch.replace(w,'');
            }
          }
        }
  
        if (!result.subcat){
          for(let i = 0; i < Const.getKeyWords().length;i++){
            let m= Const.getKeyWords()[i];
            if (searchLowered.indexOf(m.mot)!= -1){
              result.cat = m.cat;
              result.newsearch = result.newsearch.replace(m.mot,'');
              if (m.scat){
                result.subcat = m.scat;
              }
            }
            
          }
        }
        return result;
      },
  
      findDepartementIntoString(search){
        var re = /(([0-8][0-9])|(9[0-7]))[0-9]{3}/g;
        let result = new DepartementFound();
        result.newsearch = search;
        result.dept = undefined;
        let m = search.match(re);
   
        if (m) {
            let found;
            found = m[m.length-1];
            
            let count = 2;
            if (found.startsWith("97")){
            count = 3;
            }
            result.dept = parseInt(found.substr(0,count));
            result.newsearch = result.newsearch.replace(found,'');

        }

        return result;
       }
  }