<template>
  <div id="app">
    <BurgerMenu :headerState="headerState" :route="routePath" :templateMode="templateMode" v-if="templateMode>1 && headerState!=4"/>
    <HeaderComponent :headerState="headerState" :templateMode="templateMode" :route="routePath"/>
    <div class="containerPart">

      <div class="mainPart">
      <keep-alive v-if="$route.name=='Search'" include="SearchAdPage">
        <router-view :templateMode="templateMode" @navbarState="navbarSwitch" @headerState="newHeaderState" />
      </keep-alive>
      <keep-alive v-else include="SearchAdPage,CategoryPage">
        <router-view :templateMode="templateMode" @navbarState="navbarSwitch" @headerState="newHeaderState" />
      </keep-alive>
    </div>
    </div>

    <div v-if="doWeShowFooter() && templateMode==1" :style="getDivFooterStyle()"></div>
    <div v-if="doWeShowFooter() && templateMode>1" :style="getDivFooterStyleMobile()"></div>
    <Footer v-if="doWeShowFooter()" :headerState="headerState" :templateMode="templateMode" :route="routePath"/>
    <div id="emptySpace" v-if="!doWeShowFooter()"></div>
    
 <!-- <NavbarMobileComponent v-show="templateMode>1" :templateMode="templateMode" :route="routePath" v-if="defaultNavbar === true" :headerState="headerState"/>-->
  <NavbarContactComponent :templateMode="templateMode" ref="navBarContact" v-if="defaultNavbar === false"/>
  <CookiesAccept :templateMode="templateMode" :route="routePath"> </CookiesAccept>
  <Pub :templateMode="templateMode" :route="routePath"> </Pub>
  </div>
</template>

<script>
import HeaderComponent from './app/page-component/commons/header-component/header-component.vue'
import Footer from './app/page-component/commons/footer/footer.vue'
import CookiesAccept from './app/page-component/commons/cookiesAccept/cookiesAccept.vue'
import Pub from './app/page-component/commons/pub/pub.vue'
import NavbarContactComponent from './app/page-component/commons/navbar-contact-component/navbar-contact-component.vue'
import BurgerMenu from './app/page-component/commons/burgerMenu/burgerMenu.vue'
import Const from "./model/Const.js"

export default {
  name: 'App',
  components: {
    HeaderComponent,
    NavbarContactComponent,
    BurgerMenu,
    Footer,
    CookiesAccept,
    Pub
  },
  data() {
    return {
      // Navbar switch
      defaultNavbar: true,
      // Props gived to navbar-mobile, to change color in selected menu
      routePath: undefined,
      headerState: 1,
      templateMode: 1, // Mode d'affichage : 1 = PC , 2 = Tablette , 3 = Mobile
      bottomPadding:"75px",
    }
  },
 
  created() {
      console.info("App.created()")
    // Assign $route.path to default created routePath, passed as props to navbar-mobile-component
    this.routePath = this.$route.path
    Const.init();

     window.addEventListener('beforeunload', this.beforeUnloadhandler)
  },
  mounted(){
    this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
    })
    this.onResize();
  },
  updated() {
    // Assign $route.path to updated created routePath, passed as props to navbar-mobile-component
    this.routePath = this.$route.path
  },
  methods: {
 
    // Change navbar to navbar-contact on ad page
    navbarSwitch (value) {
      this.defaultNavbar = value
    },

    newHeaderState(headerStateValue){
      this.headerState = headerStateValue;
    },

    beforeUnloadhandler: function handler(event) {
      // Do Something
      console.info("beforeUnloadhandler : page is refreshing ! : " + event);
    },
    onResize() { 
      console.info("routePath = " + this.routePath + "======================!================================");
          if (window.innerWidth > 1000){
            this.templateMode = 1; // PC
            //console.info("Mode PC");
          } else if (window.innerWidth > 880){
            //this.templateMode = 2; // TABLETTE // we disable tablette mode for now
            this.templateMode = 1;
            //console.info("Mode Tablette");
          } else {
            this.templateMode = 3; // MOBILE
            //console.info("Mode Mobile");
          }
          
    },
    doWeShowFooter(){
        if (this.$route.path) {
            if (this.$route.path.startsWith("/annonce/") && this.templateMode ==3|| 
                this.$route.path.startsWith("/blog/") && this.templateMode ==3||
                this.$route.path.startsWith("/nouvelle-annonce") && this.templateMode ==3|| 
                this.$route.path.startsWith("/recherche")&& this.templateMode ==3|| 
                this.$route.path.startsWith("/monCompte/mesInformations")&& this.templateMode ==3|| 
                this.$route.path.startsWith("/monCompte/motdepasse")&& this.templateMode ==3 ||
                this.$route.path.startsWith("/contactus") && this.templateMode ==3){
                return false;
            }
        }
        return true;
    },
    getDivFooterStyle(){
      if (this.$route.path && this.$route.path.startsWith("/connexion"))
        return "padding-top:10px";
      if(this.$route.path.startsWith("/nouvelle-annonce"))
        return "padding-top:70px"
      if(this.$route.path.startsWith('/monCompte/mesInformations')) return "padding-top:290px";


     if(this.$route.path.startsWith('/monCompte/mesAnnonces')) return "padding-top:250px";
    
     if(this.$route.path.startsWith('/monCompte/motdepasse')) return "padding-top:300px";
     return "padding-top:300px";
    },
    getDivFooterStyleMobile(){
      if (this.$route.path && this.$route.path.startsWith("/connexion"))
        return "padding-top:10px";

       if (this.$route.name == 'Home')
        return "padding-top:50px";

      return "padding-top:0px";
    },
   
  }
}
</script>

<style lang="scss">
html {
  height: 100%;
  box-sizing: border-box;
}
.body-mobil {
  position: relative;
  margin: 0;
  padding-bottom: 67px;
  min-height: 100%;
  
  
}
body {
  position: relative;
  margin: 0;
  padding-bottom: 67px;
  min-height: 100%;
  background-color:white
  
}
  .containerPart{
    background-color: $background-light;
    background-repeat: repeat;
    #baniere{
      display:none;
      height: 10px;
      width: 100%;
      padding-top:50px;

    }
  }
  .mainPart{
  max-width: 1000px;
   margin: 0 auto;
   background-color: $background-light;
   
  }
   #emptySpace{
        height: 0px;
    }
</style>
