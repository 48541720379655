<script>
import InputValidatorsComponent from '../../../shared-component/input-validators-component/input-validators-component.vue'
import {ToastMixin} from '../../../../mixins/toast-mixin.js'
import {FormValidatorsMixin} from '../../../../mixins/form-validators-mixin.js'
import FavoritesService from '../../../../services/FavoritesService.js'
import AuthService from '../../../../services/AuthService.js'
import {Message} from '../../../../model/Message.js'
import UsersService from '../../../../services/UsersService.js'
import AnnoncesService from '../../../../services/AnnoncesService'
import MessagesService from '../../../../services/MessagesService'
import ModalAlertComponent from '../../../shared-component/modal-alert-component/modal-alert-component.vue'
import router from '../../../../router'
import ModalInfoComponent from '../../../shared-component/modal-info-component/modal-info-component.vue'
import OverlayComponent from '../../../shared-component/overlayComponent/overlayComponent.vue'

export default {
    name: "NavbarContactComponent",
    components: {
        InputValidatorsComponent,
        ModalAlertComponent,
        ModalInfoComponent,
        OverlayComponent
    },
    mixins: [ToastMixin, FormValidatorsMixin],
     props: ['templateMode'],
    data() {
        return {
            emailState: null,
            messageState:null,
            informationOnlyText: '',
            deleteAdMessage: "Êtes vous sur de vouloir supprimer l'annonce ?",
            amoi: false,
            // DEV Should come from backend (favorite ad item or not)
            favorite: false,
            // Show phone & sms overlay
            showContactPhone: false,
            // Show email overlay
            showContactMail: false,
            // Show sharing overlay
            showSharing: false,
            phoneOption:false,
            mailOption:false,
            // Email form & formValid
            form: {
                userName: '',
                userEmail:'',
                targetEmail: '',
                emailMessage: ''
            },
            formValid: {
                userName: false,
                targetEmail: false,
                emailMessage: false
            },
            annonceTel: "0606060606",
            emailSubject:"Votre annonce sur Showroombaby",
            smsBody:"Bonjour, concernant votre annonce sur Showroombaby,",
            // Url to share: (rootNetlify for mobile testing, rootLocalhost for desktop testing)
            rootNetlify: 'https://showroombaby.com/%23',
            rootLocalhost: 'http://localhost:8080/#',
            adPathLocal: this.rootLocalhost + this.$route.path,
            // Mobile device boolean (true = mobile, false = desktop)
            mobileDevice: undefined,
            annonceUser: undefined,
            needToRegisterMessage: "",
            contactByPhoneForbiddenByUser: false,
            connectezVousMsg:"Connectez-vous pour accéder à toutes les fonctionnalités.",
            annonce:undefined,
        }
    },
    created() {
        this.favorite = undefined;
        this.adPathLocal = this.rootNetlify + this.$route.fullPath;
        console.info("share url = " + this.adPathLocal);
        // On created check if device is mobile or not (change sharing button display/logic)
        this.mobileDevice = this.checkMobile();

         if (AuthService.isLogged()){
             UsersService.getSingleUser(AuthService.getCurrentUserId()).then((user)=>{
                    this.form.userName = user.nom;
                    console.info("this.form.userName = " + this.form.userName);
                });
         } else {
             this.form.userName = "a ShowroomBaby User";
         }
        

        AnnoncesService.getAnnonce(this.$route.params.adId).then(
            (annonce)=>{
                this.annonce = annonce;
                if (AuthService.isLogged() && AuthService.getCurrentUserId() == annonce.userId){
                        this.amoi = true;
                } else {
                    this.amoi = false;
                }
            
                this.phoneOption = true;
                this.mailOption = true;
                if (AuthService.isLogged()){
                    this.updateFavoriteState();
                } else {
                    this.favorite = undefined;
                }
            
                this.emailSubject = "Votre annonce '"+annonce.titre+"' sur Showroombaby";
                this.smsBody = "Bonjour, concernant votre annonce '"+annonce.titre+"' sur Showroombaby,";
                this.annonceTel = annonce.tel;
                this.contactByPhoneForbiddenByUser = annonce.notel;
                UsersService.getSingleUser(annonce.userId).then((user)=>{
                    this.annonceUser = user;
                    this.form.targetEmail = user.email;
                
                });
                
            });
      
         
        

         
    },
    mounted(){
        this.$root.$on('new_favorite_state_from_ad_page',(newState) =>{
            this.favorite = newState;
        });

        this.$root.$on('contact_action',(action_type) =>{
            if (action_type==1){
                this.clickContactPhone();
            } else if (action_type==2){
                this.clickContactEmail();
            } else if (action_type==3){
                this.clickSuppressionAnnonce();
            }
        });
       

        if (AuthService.isLogged()){
            
            UsersService.getSingleUser(AuthService.getCurrentUserId()).then((user)=>{
                this.form.userEmail = user.email;
            })
        }
    },
    methods: {
        checkFormValidity() {
            const validEmail = this.$refs.emailInput.checkValidity();
            this.emailState = validEmail && this.form.userEmail.length >= 6;
            this.messageState = this.form.emailMessage.length>=10;
            return this.emailState && this.messageState ;
        },
        clickSurSendEmail(){
           if (!this.checkFormValidity()) {
                return;
            }
               this.showContactMail=false;
            let message = new Message(AuthService.isLogged()?AuthService.getCurrentUserId():undefined,
                this.form.userEmail,
                this.form.emailMessage,
                this.annonceUser.firestoreId,
                this.annonceUser.email,
                this.annonceUser.nom,
                this.annonce.titre,
                this.annonce.firestoreId,

            );
            MessagesService.sendMessage(message).then(()=>{
                this.informationOnlyText = "Votre message a été envoyé avec succès. L'utilisateur vous répondra par email.";
                this.$nextTick(() => {
                    this.$refs.informationOnly.showModal();
                });
            });
         
           
        },
        closeMail(){
            this.showContactMail=false;
        },
        closeContactPhone(){
            this.showContactPhone=false;
        },
        clickSuppressionAnnonce(){
            this.$refs.modalAlertDelete.showModal()
        },
        cancelDeleteFunc(){
            console.info("click sur Cancel : suppression d'annonce");
        },
        okDeleteFunc(){
            console.info("click sur Ok");
            AnnoncesService.deleteAnnonce(this.$route.params.adId).then(()=>{
                console.info("annonce effacee");
                 router.push('/monCompte/mesAnnonces');
            });
        },
        okConnectezVous(){
            console.info("okConnectezVous()");
            router.push('/connexion?ad='+this.$route.params.adId);
        },
        okInformationOnly(){
            console.info("okInformationOnly");
        },
        // Manage favorite items, backend + toast feedback
        manageFavorite() {
            if (this.favorite == undefined){
                this.$refs.connectezVousModal.showModal();
            } else if(this.amoi){
                 this.informationOnlyText = "Vous ne pouvez pas ajouter aux favoris votre propre annonce. Vous pouvez cependant la retrouver dans la rubrique : Mon Compte > Mes annonces.";
                this.$nextTick(() => {
                        this.$refs.informationOnly.showModal();
                    });
            }else {
                console.info("parameters : " + this.$route.params.adId);
                let firestoreId = this.$route.params.adId;
                let toastMessage = 'addition-toast';
                if (this.favorite == true) toastMessage = 'deletetion-toast';
                
                if (!this.favorite){
                    FavoritesService.addToFavoriteWithFireStoreId(firestoreId).then(()=> this.updateFavoriteState()).then(()=>this.popToast(this.adFavoriteMessage, toastMessage));
                } else {
                    FavoritesService.deleteFavoriteWithFireStoreId(firestoreId).then(()=> this.updateFavoriteState()).then(()=>this.popToast(this.adUnfavoriteMessage, toastMessage));
                }

                this.$root.$emit('new_favorite_state_from_nav_bar',!this.favorite);
            }
        },

         updateFavoriteState(){
             let firestoreId = this.$route.params.adId;
            return FavoritesService.isFavoriteWithFireStoreId(firestoreId).then((b)=>{
                this.favorite = b;
            },() =>{
                this.favorite = undefined;
            });

         },
        // Sharing button logic, mobile
        shareMobile() {
            console.info("we share URL : " + this.adPathLocal);
            if (navigator.share) {
                
                navigator.share({
                    title: 'ShowroomBaby annonce',
                    url: this.adPathLocal,
                }).then(() => {
                    // this will never happen in IOS 14.0.1
                })
                .catch(error => {
                    console.error(error);
                    //this will catch the second share attempt
                    window.location.reload(true); // now share works again
                });
            } else {
                console.log('Unsupported : web browser too old or you are on desktop device')
            }
        },
        // Check if device is mobile or not
        checkMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
                return true
            }
            else {
                return false
            } 
        },

        cancelRegisterFunc(){
            console.info("click sur annuler"); 
        },
        okRegisterFunc(){
            console.info("click sur Ok");
            router.push('/connexion');
        },
        clickContactPhone(){
            if (this.contactByPhoneForbiddenByUser){
                 this.informationOnlyText = "L'utilisateur ne souhaite pas être contacté par téléphone.";
                this.$refs.informationOnly.showModal();
            } else if (this.phoneOption==undefined){
                this.$refs.connectezVousModal.showModal();
            } else {
                this.showContactPhone = true;
                this.showContactMail = false;
                this.showSharing = false;
            }
        },
        clickContactEmail(){
            if (this.mailOption==undefined){
                this.$refs.connectezVousModal.showModal();
            } else {
                this.showContactMail=true;
                this.showContactPhone = false;
                this.showSharing = false;
            }
           
        }
    }
};
</script>

<style lang="scss">
    @import "navbar-contact-component.scss";
</style>

<template src="./navbar-contact-component.html"></template>